import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import Plot from "react-plotly.js";
import "./VisitDataSummary.scss";
import CalendarViewWeekMode from "../../../components/CalendarViewWeekMode/CalendarViewWeekMode";
import { Select } from "antd";
import dayjs from "dayjs";
import { httpService } from "../../../../services/httpService.service";
import Filters from "./Filters";
const { Option } = Select;
// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const TrainingAnalysis = () => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs().format("YYYY-MM"));
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [siteVisits, setSiteVisits] = useState([]);
  const [selectedYear, setSelectedYear] = useState(dayjs().year());

  const projectID = localStorage.getItem("currentProjectID");
  // Get number of weeks in the selected month
  const getWeeksInMonth = (month) => {
    const firstDay = dayjs(month).startOf("month");
    const lastDay = dayjs(month).endOf("month");
    return Math.ceil(lastDay.date() / 7);
  };

  const totalWeeks = getWeeksInMonth(selectedMonth);

  // useEffect(() => {
  //   const params = {
  //     ProjectId: projectID,
  //     IgnorePagination: true,
  //     IncludeOnlyWithTracking: true,
  //   };

  //   const queryString = new URLSearchParams(params).toString();

  //   httpService.get(`/api/Site/getAllSiteVisits?${queryString}`, (res) => {
  //     const data = res?.data;
  //     const groupedData = data?.reduce((acc, siteVisit) => {
  //       const state = siteVisit.locationDetails?.country || "Unknown";
  //       if (!acc[state]) acc[state] = [];
  //       acc[state].push({
  //         key: siteVisit.id,
  //         name: siteVisit.name,
  //         identifier: siteVisit.identifier,
  //         estimatedDayOfVisit: siteVisit.visit?.plannedVisit || "",
  //         trainingDate: siteVisit.tracking?.trainingDate || "",
  //       });
  //       return acc;
  //     }, {});

  //     // Transform data to an array with state names
  //     const structuredData = Object.entries(groupedData || {}).map(
  //       ([state, visits]) => ({
  //         state,
  //         countOfVisits: visits?.length,
  //         totalDays: visits?.reduce(
  //           (sum, visit) => sum + (parseInt(visit?.totalDays) || 0),
  //           0
  //         ),
  //         visits,
  //       })
  //     );
  //     console.log(structuredData);
  //     setSiteVisits(structuredData);
  //   });
  // }, []);

  const handleYearChange = (value) => {
    setSelectedYear(value);
    setSelectedMonth(`${value}-01`);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleWeekChange = (value) => {
    setSelectedWeek(value);
  };

  return (
    <>
      <div className="filters">
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          style={{ width: 100, marginRight: 10 }}
        >
          {Array.from({ length: 5 }, (_, i) => {
            const year = dayjs().year() - (4 - i);
            return (
              <Option key={year} value={year}>
                {year}
              </Option>
            );
          })}
        </Select>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          style={{ width: 150, marginRight: 10 }}
        >
          {Array.from({ length: 12 }, (_, i) => {
            const month = dayjs().month(i).format("YYYY-MM");
            return (
              <Option key={month} value={month}>
                {dayjs(month).format("MMMM YYYY")}
              </Option>
            );
          })}
        </Select>
        <Select
          value={selectedWeek}
          onChange={handleWeekChange}
          style={{ width: 100 }}
        >
          {Array.from({ length: totalWeeks }, (_, i) => (
            <Option key={i + 1} value={i + 1}>
              Week {i + 1}
            </Option>
          ))}
        </Select>
      </div>
      <div className="calendarView">
        <div
          className="d-flex flex-column align-items-center w-100"
          style={{ gap: "32px" }}
        >
          <h2>Count of Visits by State Level</h2>
          <div
            className="w-100 d-flex flex-wrap justify-content-center"
            style={{ gap: "32px" }}
          >
            <div className="d-flex flex-row gap-1 align-items-center">
              <div
                style={{
                  width: "18.54px",
                  height: "12.36px",
                  background: "var(--Graphs-6, #B9A5AF)",
                }}
              />
              <p
                className="m-0 p-0"
                style={{
                  color: "var(--9, #404040)",
                  fontFamily: "Poppins",
                  fontSize: "13.596px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                Training
              </p>
            </div>
            <div className="d-flex flex-row gap-1 align-items-center">
              <div
                style={{
                  width: "18.54px",
                  height: "12.36px",
                  background: "var(--Graphs-6, #A7587F)",
                }}
              />
              <p
                className="m-0 p-0"
                style={{
                  color: "var(--9, #404040)",
                  fontFamily: "Poppins",
                  fontSize: "13.596px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                Visits
              </p>
            </div>
            <div className="d-flex flex-row gap-1 align-items-center">
              <div
                style={{
                  width: "18.54px",
                  height: "12.36px",
                  background: "var(--Graphs-6, #EF7DB5)",
                }}
              />
              <p
                className="m-0 p-0"
                style={{
                  color: "var(--9, #404040)",
                  fontFamily: "Poppins",
                  fontSize: "13.596px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                }}
              >
                Training and visit
              </p>
            </div>
          </div>
        </div>

        <div className="calendarContainer w-100">
          <div className="tableHeader">
            <div className="month">
              {dayjs(selectedMonth).format("MMMM YYYY")}
            </div>
          </div>
          <CalendarViewWeekMode
            // data={siteVisits}
            selectedWeek={selectedWeek}
            selectedMonth={selectedMonth}
          />
        </div>
      </div>
    </>
  );
};

export default TrainingAnalysis;
