import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Tooltip,
  Space,
  Divider,
  Spin,
  Progress,
} from "antd";
import "./style.scss";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import uploadIcon from "../../../../Assets/svg/uploadIcon.svg";
import FormItem from "antd/es/form/FormItem";
import PlanningIcon from "../../../../Assets/svg/iconMenu2.svg";
import RemoveIcon from "../../../../Assets/svg/removeIcon.svg";
import cancelX from "../../../../Assets/svg/cancelXbutton.svg";
import Done from "../../../../Assets/svg/done.svg";
import ExclamationMark from "../../../../Assets/svg/exclamationMark.svg";
import { PlusOutlined } from "@ant-design/icons";
import { httpService } from "../../../../services/httpService.service";
import showConfirm from "../../../components/ExitConfirm/ExitConfirm";
import axios from "axios";
import { debounce } from "lodash";

const { Option } = Select;

const AddModal = ({ visible, onClose, setMessage, onAdd }) => {
  const [loading, setLoading] = useState(false);
  const [savingPorgress, setSavingPorgres] = useState(false);
  const [form] = Form.useForm();
  const projectID = localStorage.getItem("currentProjectID");
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const inputRef = useRef(null);
  const [clusters, setClusters] = useState([]);
  const [newCluster, setNewCluster] = useState("");
  const [addingCluster, setAddingCluster] = useState(false);
  const [users, setUsers] = useState();
  const [implementingPartners, setImplementingPartners] = useState();
  const [selectedIp, setSelectedIp] = useState({});
  const [fileList, setFileList] = useState([]);
  const [selectedCityIds, setSelectedCityIds] = useState([]);
  const [selectedStateIds, setSelectedStateIds] = useState(null);
  const [loadingStates, setLoadingStates] = useState(false);
  const [ipPlanInputId, setIpPlanInputId] = useState("###");
  const [loadingFiles, setLoadingFiles] = useState([]);

  const startYear = 2024;
  const endYear = 2034;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, i) => startYear + i
  );

  const deleteFilesFromDB = async (fileKeys) => {
    await axios
      .request({
        method: "DELETE",
        url: "https://kapfilemanager.kapsurvey.com/FileData/deleteFileByFileKey",
        headers: {
          "Content-Type": "application/json",
        },
        data: fileKeys,
      })
      .then((res) => {
        console.log("File deleted successfully", res);
      })
      .catch((error) => {
        console.log("Error deleting file", error?.response?.data);
      });
  };

  function formatFileUUID(uid) {
    const strippedId = uid.replace("rc-upload-", "").replace(/-/g, "");

    const part1 = strippedId.slice(0, 8).padEnd(8, "2");
    const part2 = strippedId.slice(8, 12).padEnd(4, "2");
    const part3 = strippedId.slice(12, 16).padEnd(4, "c");
    const part4 = strippedId.slice(16, 20).padEnd(4, "c");
    const part5 = strippedId.slice(20).padEnd(12, "2");

    return `${part1}-${part2}-${part3}-${part4}-${part5}`;
  }

  function reformatToOriginalUUID(formattedUUID) {
    const arrayWithoutDashes = formattedUUID.replace(/-/g, "");

    const orgPart = arrayWithoutDashes.split("c")[0];

    const part1 = orgPart.slice(0, 13);
    const part2 = orgPart.slice(13);
    return `rc-upload-${part1}-${part2}`;
  }

  const debouncedHandleUploadChange = debounce((files) => {
    const newFiles = files.reduce((acc, file) => {
      if (!fileList.some((doc) => doc.uid === file.uid)) {
        acc.push({ ...file, percent: 0 });
      }
      return acc;
    }, []);

    setLoadingFiles(newFiles);

    const formData = new FormData();
    formData.append("FolderName", "TPM");
    formData.append("PlatformId", "507e23bd-882e-4c0c-85a8-a020190a06f3");

    newFiles?.forEach((file, index) => {
      formData.append(
        `entityFormFileDtos[${index}].entityId`,
        formatFileUUID(file.uid)
      );
      formData.append(
        `entityFormFileDtos[${index}].formFile`,
        file.originFileObj
      );
    });

    axios
      .post("https://kapfilemanager.kapsurvey.com/FileData/addFile", formData, {
        headers: {
          Accept: "text/plain",
        },
        onUploadProgress: ({ loaded, total }) => {
          const percent = Math.round((loaded / total) * 100);

          setLoadingFiles((prevFiles) =>
            prevFiles?.map((f) => ({ ...f, percent }))
          );
        },
      })
      .then((response) => {
        setLoadingFiles([]);
        setFileList((prev) =>
          [
            newFiles
              ?.map((file) => {
                const matchingFileData = response?.data?.filter(
                  (fileData) =>
                    reformatToOriginalUUID(fileData.entityId) == file.uid
                );
                if (matchingFileData?.length) {
                  return matchingFileData?.map((fileData) => ({
                    ...file,
                    fileDataId: fileData.fileDataId,
                    fileKey: fileData.fileKey,
                  }));
                }
                return file;
              })
              .flat(),
            ...prev,
          ].flat()
        );
      })
      .catch((error) => {
        setLoadingFiles((prevFiles) =>
          prevFiles?.filter(
            (file) => !newFiles.some((newFile) => newFile.uid === file.uid)
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, 300);

  const handleFileChange = ({ fileList }) => {
    setLoading(true);
    debouncedHandleUploadChange(fileList);
  };

  const fetchClusterData = async () => {
    setLoading(true);
    httpService.get(
      "/api/Site/getAllClusters",
      (response) => setClusters(response.data),
      (error) => {
        console.log(error);
      }
    );

    setLoading(false);
  };

  const addCluster = async (e) => {
    e.preventDefault();
    if (newCluster) {
      setLoading(true);
      httpService.post(
        "/api/Site/createCluster",
        {
          name: newCluster,
        },
        (response) => {
          const message =
            response.message ||
            `A new cluster has been created and added to your database!`;

          fetchClusterData();
          setNewCluster("");
          setAddingCluster(false);
          setMessage({
            trigger: true,
            type: "success",
            icon: Done,
            title: `Cluster added successfully!`,
            text: message,
          });
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message ||
            `We had a problem adding this cluster to your database, please try again!`;

          setMessage({
            trigger: true,
            type: "danger",
            icon: ExclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      );

      setLoading(false);
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);

      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };
  const handleClusterSelectChange = () => {
    setAddingCluster(false);
  };

  useEffect(() => {
    setSelectedIp({});
    const workspaceId = localStorage.getItem("currentContractId");
    httpService.get(
      `/api/User/getAllusers?RoleIds=e78550f4-c017-4ec7-94e8-e040090c32b1&pageSize=1000&ContractIds=${workspaceId}&IgnorePagination=true`,
      (res) => {
        setUsers(res.data);
      },
      (error) => {
        console.log(error);
      }
    );

    fetchClusterData();

    const apiIps = `/api/ImplementingPartner/getAllImplementingPartners?contractId=${workspaceId}&IgnorePagination=true`;

    httpService.get(
      apiIps,
      (res) => setImplementingPartners(res.data),
      (error) => console.log(error)
    );
  }, []);

  const handleIpChange = (implementingPartnerId) => {
    setLoadingStates(true);
    setSelectedIp({});
    setSelectedCityIds([]);
    setCities([]);
    form.resetFields(["state", "city"]);
    if (implementingPartnerId) {
      const selectedIp = implementingPartners.find(
        (ip) => ip.id === implementingPartnerId
      );
      setSelectedIp(selectedIp);
      const states = selectedIp?.states;
      setStates(states);
      const cities = selectedIp?.cities;
      if (selectedIp?.cities?.length > 0) {
        setCities(cities);
      }
    }
    setTimeout(() => {
      setLoadingStates(false);
    }, 500);
  };

  const handleCityChange = (cityIds) => {
    setSelectedCityIds(cityIds);
  };

  const handleStateChange = (stateIds) => {
    setSelectedStateIds(stateIds);
  };

  const handleResourceRemove = (file) => {
    deleteFilesFromDB([file.fileKey]);

    const updatedFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(updatedFileList);
  };

  const handleSaveProgress = () => {
    setSavingPorgres(true);

    const values = form.getFieldsValue();

    const allFieldsEmpty = Object.values(values).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      }
      return !value;
    });

    if (allFieldsEmpty) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: PlanningIcon,
        title: "Validation Error",
        text: "You cannot save this IP Plan Card as a draft if all fields are empty.",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 1000);
      setSavingPorgres(false);
      return;
    }

    const filesData = fileList.map((file) => ({
      fileName: file?.name,
      fileKey: file?.fileKey,
      fileSize: file?.size,
    }));

    let selectedIp;
    if (values?.ImplementingPartnerId) {
      selectedIp = implementingPartners?.find(
        (ip) => ip.id === values?.ImplementingPartnerId
      );
    }

    let selectedClusters;
    if (values.cluster) {
      selectedClusters = clusters?.filter((cluster) =>
        values.cluster.includes(cluster.id)
      );
    }

    let slectedUsers;
    if (values.AssignedTo) {
      slectedUsers = users?.find((user) => user.id === values.AssignedTo);
    }

    const ipPlanDraftData = {
      ipPlanStates: values.states || undefined,
      ipPlanCities: values.cities || undefined,
      implementingPartner: selectedIp,
      ...(values.ipPlanId && {
        ipPlanId: values.ipPlanId,
      }),
      ...(values?.OfficeId && {
        office: {
          id: values.OfficeId,
        },
      }),
      ...(selectedStateIds &&
        selectedStateIds.length && {
          StateIds: selectedStateIds,
        }),
      ...(selectedCityIds && {
        cityId: selectedCityIds,
      }),
      ...(slectedUsers && {
        assignedTo: slectedUsers,
      }),
      ...(selectedClusters && {
        clusterId: selectedClusters,
      }),
      ...(values.generalInfo && { generalInfo: values.generalInfo }),
      ...(values?.programDescription && {
        programDescription: values.programDescription,
      }),
      ...(values.InitialMeeting && { initialMeeting: values.InitialMeeting }),
      ...(values.Month && { month: values.Month }),
      ...(values.Year && { year: values.Year }),
      ...(values.contactName && {
        pointOfContact: {
          name: values.contactName,
          ...(values.contactRole && { role: values.contactRole }),
          ...(values.contactEmail && { email: values.contactEmail }),
          ...(values.contactPhone && {
            organisation: values.contactPhone,
            phone: values.contactPhone,
          }),
          ...(values.contactSkype && { skype: values.contactSkype }),
        },
      }),
      ...(values.currentIpStatus && {
        currentIpStatus: values.currentIpStatus,
      }),
      ...(filesData?.length && { fileList: filesData }),
    };

    const projectId = localStorage.getItem("currentProjectID");
    const jsonData = JSON.stringify(ipPlanDraftData);
    const payload = {
      ProjectId: projectId,
      jsonData: jsonData,
      type: 2,
    };

    httpService
      .post(
        "/api/Draft/createOrEditDraft",
        payload,
        (res) => {
          const message =
            res.message ||
            "A new draft IP plan card has been created and added to your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: PlanningIcon,
            title: "New draft IP plan card added successfully!",
            text: message,
          });
          onAdd(res.data);
          handleLeave("submit");
        },
        (error) => {
          console.error("There was an error adding the IP Plan Card!", error);

          let errorMessage;
          if (error?.message) {
            errorMessage = error.message;
          } else {
            errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem adding this draft IP plan card from your database, please try again.";
          }

          setMessage({
            trigger: true,
            type: "danger",
            icon: PlanningIcon,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
        }
      )
      .finally(() => {
        setSavingPorgres(false);
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 1000);
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);

        const contractId = localStorage.getItem("currentContractId");
        const formData = new FormData();
        formData.append("IPPLanCode", values.ipPlanId || "");
        formData.append("ImplementingPartnerId", values.ImplementingPartnerId);
        selectedStateIds.forEach((stateId) => {
          formData.append("StateIds", stateId);
        });
        selectedCityIds.forEach((cityId) => {
          formData.append("CityIds", cityId);
        });
        formData.append("ProjectId", projectID);
        formData.append("ContractId", contractId);
        formData.append("AssignedTo", values.AssignedTo);
        values.cluster.forEach((cluster, index) =>
          formData.append(`Clusters[${index}]`, cluster)
        );
        if (values.generalInfo) {
          formData.append("generalInfo", values.generalInfo || "");
        }

        if (values.programDescription !== undefined) {
          formData.append(
            "PlanningRound.ProgramDescription",
            values?.programDescription
          );
        }
        formData.append(
          "InitialMeeting",
          values.InitialMeeting
            ? values.InitialMeeting.format("YYYY-MM-DD")
            : null
        );

        if (values.Month) formData.append("PlanningRound.Month", values.Month);
        if (values.Year) formData.append("PlanningRound.Year", values.Year);
        if (values.contactName)
          formData.append("PointOfContact.Name", values.contactName);
        if (values.contactEmail)
          formData.append("PointOfContact.Email", values.contactEmail);
        if (values.contactRole)
          formData.append("PointOfContact.Role", values.contactRole);
        if (values.contactPhone)
          formData.append("PointOfContact.Phone", values.contactPhone);
        if (values.contactSkype)
          formData.append("PointOfContact.Skype", values.contactSkype);
        if (values.contactOrganization)
          formData.append(
            "PointOfContact.Organization",
            values.contactOrganization
          );

        formData.append("PointOfContact.ContactType", 2);

        formData.append("CurrentIPStatus", values.currentIpStatus || "");
        formData.append("IPDonorReportingEnum", 2 || "");

        fileList.forEach((file, index) => {
          formData.append(`Files[${index}].fileName`, file?.name);
          formData.append(`Files[${index}].fileKey`, file?.fileKey);
          formData.append(`Files[${index}].fileSize`, file?.size);
          formData.append(`Files[${index}].FileStatus`, 1);
        });

        httpService
          .upload(
            "/api/IPPlan",
            formData,
            (res) => {
              const message =
                res.message ||
                "A new IP Plan Card has been created and added to your database.";
              setMessage({
                trigger: true,
                type: "success",
                icon: PlanningIcon,
                title: "New IP Plan Card added successfully!",
                text: message,
              });
              onAdd(res);
              handleLeave("submit");
              setFileList([]);
              form.resetFields();
            },
            (error) => {
              console.error(
                "There was an error adding the IP Plan Card!",
                error
              );

              let errorMessage;
              if (error?.message) {
                errorMessage = error.message;
              } else {
                errorMessage =
                  error?.errors?.[0]?.message ||
                  "We had a problem adding this IP Plan Card from your database, please try again.";
              }

              setMessage({
                trigger: true,
                type: "danger",
                icon: PlanningIcon,
                title: "Oops! A problem has occurred!",
                text: errorMessage,
              });
            }
          )
          .finally(() => {
            setLoading(false);
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 1000);
          });
      })
      .catch((errorInfo) => {
        console.error("Validation Failed:", errorInfo);
        scrollToField(errorInfo);
        setMessage({
          trigger: true,
          type: "danger",
          icon: PlanningIcon,
          title: "Oops! A problem has occurred!",
          text:
            errorInfo.errorFields?.length > 0
              ? "Please fill all the required fields."
              : "An unexpected error occurred.",
        });
        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
      });
  };

  const scrollToField = (errorInfo) => {
    const firstErrorField = errorInfo?.errorFields[0];
    if (firstErrorField) {
      form.scrollToField(firstErrorField?.name[0], {
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = (submit) => {
    form.resetFields();
    if (submit != "submit" && fileList?.length) {
      const fileKeys = fileList?.map((file) => file.fileKey) || [];
      deleteFilesFromDB(fileKeys);
    }

    setFileList([]);
    setLoadingFiles([]);
    setCities([]);
    setSelectedIp({});
    setSelectedStateIds([]);
    setSelectedCityIds([]);
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const handleFileSize = (file) => {
    const isValidSize = file.size / 1024 / 1024 <= 5;
    if (!isValidSize) {
      setMessage({
        trigger: true,
        type: "danger",
        icon: ExclamationMark,
        title: "Oops! A problem has occurred!",
        text: "File must be smaller than 5MB!",
      });
      setTimeout(() => {
        setMessage({
          trigger: false,
        });
      }, 3000);
      return false; // Prevent upload
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the desired width and height
          const maxWidth = 800;
          const maxHeight = 600;
          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            if (width / height > maxWidth / maxHeight) {
              width = maxWidth;
              height = Math.round((img.height * maxWidth) / img.width);
            } else {
              width = Math.round((img.width * maxHeight) / img.height);
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              resolve(resizedFile); // Return the resized file
            },
            file.type,
            0.7 // Optional: adjust the quality (for JPG only)
          );
        };
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const today = new Date();

  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const year = today.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return (
    <Modal
      destroyOnClose
      open={visible}
      closable={false}
      width={800}
      onCancel={handleCancel}
      footer={[
        <Button
          onClick={handleSaveProgress}
          key="back"
          loading={loading || savingPorgress}
          className="inviteButtonTPM"
        >
          Save as draft
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          loading={loading || savingPorgress}
          className="addButtonTPM"
        >
          Add IP plan card
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
        />
      </div>
      <h2 className="modalHeader">New IP Plan Card</h2>
      <Form form={form} layout="vertical">
        <Form.Item label="IP Plan Card Identifier" name="identifier">
          <div className="form-control-plaintext">
            <p
              style={{
                border: "2px solid #d9d9d9",
                borderRadius: "10px",
                padding: "8px 12px",
                fontSize: "14px",
                color: "#495057",
              }}
            >
              <Tooltip title="This number will be generated automatically!">
                <span>00X - </span>
              </Tooltip>
              <Tooltip title="Selected Implementing Partner!">
                <span>
                  {selectedIp?.name !== undefined
                    ? selectedIp?.name
                    : "Implementing Partner"}
                </span>
              </Tooltip>
              <Tooltip title="Created date!">
                <span>{`- ${formattedDate}`}</span>
              </Tooltip>
            </p>
          </div>
        </Form.Item>
        <Form.Item
          label="IP Project Code  (optional)"
          name="ipPlanId"
          rules={[
            {
              maxLength: 10,
              message: "Input must be maximum length 10 characters long!",
            },
          ]}
        >
          <Input
            style={{ width: "140px" }}
            onChange={(e) => setIpPlanInputId(e.target.value)}
            maxLength={10}
            placeholder="###"
          />
        </Form.Item>
        <h3>General details</h3>
        <Form.Item
          name="ImplementingPartnerId"
          label="Implementing Partner"
          rules={[{ required: true, message: "Select an IP" }]}
        >
          <Select
            placeholder="Select an IP"
            onChange={handleIpChange}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            {implementingPartners &&
              implementingPartners.map((implementingPartner) => (
                <Option
                  key={implementingPartner.id}
                  value={implementingPartner.id}
                >
                  {implementingPartner.name}
                </Option>
              ))}
          </Select>
        </Form.Item>
        <div className="firstSection">
          <div className="row">
            {isEmptyObject(selectedIp) ? (
              ""
            ) : (
              <Spin spinning={loadingStates}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item
                    name="country"
                    label="Country"
                    tooltip="County selected for this project round!"
                    style={{ width: "48%" }}
                  >
                    <p className="previewText my-2">
                      {selectedIp?.states?.[0]?.country?.stateName}
                    </p>
                  </Form.Item>
                  <Form.Item
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: "Please select one or more states",
                      },
                    ]}
                    tooltip={
                      cities?.length < 1
                        ? "Cities selected for this implementing partner!"
                        : "States selected for this implementing partner!"
                    }
                    label={
                      cities?.length < 1
                        ? "City/Disctrict/Municipality"
                        : "Governorate/State"
                    }
                    style={{ width: "48%" }}
                  >
                    <Select
                      showSearch
                      allowClear
                      mode="multiple"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) === 0
                      }
                      placeholder="Select one or more states"
                      onChange={handleStateChange}
                      disabled={states && !states?.length}
                    >
                      {states.map((state) => (
                        <Option key={state.id} value={state.id}>
                          {state.stateName}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item
                  name="city"
                  tooltip="Cities selected for this implementing partner!"
                  label="City/Disctrict/Municipality"
                  className={cities?.length > 0 ? "" : "d-none"}
                  rules={[
                    {
                      required: cities?.length > 0,
                      message: "Please select one or more cities",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) === 0
                    }
                    placeholder="Select one or more cities"
                    onChange={handleCityChange}
                    disabled={cities && !cities?.length}
                  >
                    {cities.map((city) => (
                      <Option key={city.id} value={city.id}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Spin>
            )}
            <Form.Item
              name="AssignedTo"
              label="Assigned to a field coordinator"
              rules={[
                {
                  required: true,
                  message: "Select a person to assign the plan",
                },
              ]}
            >
              <Select
                placeholder="Select a person to assign the plan"
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) ===
                  0
                }
              >
                {users &&
                  users.map((user) => (
                    <Option key={user.id} value={user.id}>
                      {user.userDetails?.firstName && user.userDetails?.lastName
                        ? `${user.userDetails.firstName} ${user.userDetails.lastName}`
                        : user.userName}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Clusters"
              name="cluster"
              rules={[
                {
                  required: true,
                  message: "Please select a cluster",
                },
              ]}
            >
              <Select
                filterOption={(input, option) =>
                  option.label.toLowerCase().startsWith(input.toLowerCase())
                }
                width={100}
                allowClear
                placeholder="Select an item"
                mode="multiple"
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    {addingCluster ? (
                      <Space style={{ padding: "0 8px 4px" }}>
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={newCluster}
                          onChange={(e) => setNewCluster(e.target.value)}
                          onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button
                          type="text"
                          loading={loading}
                          icon={<PlusOutlined />}
                          onClick={addCluster}
                        >
                          Add item
                        </Button>
                      </Space>
                    ) : (
                      <Space
                        style={{
                          padding: "0 8px 4px",
                          cursor: "pointer",
                          color: "var(--2, #AA1A5F)",
                        }}
                        onClick={() => setAddingCluster(true)}
                      >
                        <PlusOutlined /> Other
                      </Space>
                    )}
                  </>
                )}
                onChange={handleClusterSelectChange}
                onDropdownVisibleChange={(open) => {
                  !open && setAddingCluster(false);
                  setNewCluster();
                }}
                options={clusters.map((cluster) => ({
                  label: cluster.name,
                  value: cluster.id,
                }))}
              />
            </Form.Item>
          </div>
        </div>
        <Form.Item
          name="generalInfo"
          label="General information about on-site operations"
          rules={[
            { required: true, message: "General information is required" },
          ]}
        >
          <Input.TextArea placeholder="Enter general information about operations" />
        </Form.Item>
        <Form.Item
          name="programDescription"
          label="IP Program Activities Description"
        >
          <Input.TextArea placeholder="Enter a description of the program" />
        </Form.Item>
        <div className="secondSection">
          <div className="row">
            <div className="column">
              <Form.Item
                name="InitialMeeting"
                label="Initial Meeting"
                rules={[
                  { required: true, message: "Initial Meeting is required" },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="assignedToRoundLabel py-3 pb-2">
          <span>Assigned to round</span>
          <Tooltip title="Information about assignment to rounds">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
        <div className="row">
          <div className="column">
            <Form.Item
              name="Month"
              label="Month"
              rules={[{ required: true, message: "Month is required" }]}
            >
              <Select placeholder="Select month" style={{ width: "100%" }}>
                <Option value="1">January</Option>
                <Option value="2">February</Option>
                <Option value="3">March</Option>
                <Option value="4">April</Option>
                <Option value="5">May</Option>
                <Option value="6">June</Option>
                <Option value="7">July</Option>
                <Option value="8">August</Option>
                <Option value="9">September</Option>
                <Option value="10">October</Option>
                <Option value="11">November</Option>
                <Option value="12">December</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="column">
            <Form.Item
              name="Year"
              label="Year"
              rules={[{ required: true, message: "Year is required" }]}
            >
              <Select
                showSearch
                placeholder="Select year"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="assignedToRoundLabel">
          <span>IP Point of contact</span>
          <Tooltip title="Information about assignment to rounds">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
        <div className="contact-info">
          <div className="row">
            <div className="column">
              <Form.Item
                name="contactName"
                label="Contact Name"
                rules={[
                  { required: true, message: "Contact Name is required" },
                ]}
              >
                <Input placeholder="Enter your name" />
              </Form.Item>
              <Form.Item name="contactEmail" label="Email">
                <Input placeholder="Enter your email" />
              </Form.Item>
            </div>

            <div className="column">
              <Form.Item
                name="contactRole"
                label="Role"
                rules={[{ required: true, message: "Please select role" }]}
              >
                <Input placeholder="Enter role" />
              </Form.Item>
              <Form.Item
                name="contactOrganization"
                label="Organization"
                rules={[
                  { required: true, message: "Organization is required" },
                ]}
              >
                <Input placeholder="Enter your organization" />
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item
                name="contactPhone"
                label="Phone"
                rules={[
                  { required: true, message: "Phone number is required" },
                ]}
              >
                <Input placeholder="Enter your phone number" />
              </Form.Item>
            </div>
            <div className="column">
              <Form.Item name="contactSkype" label="Name of the tool">
                <Input placeholder="Enter your Name of the tool" />
              </Form.Item>
            </div>
          </div>
        </div>{" "}
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="column">
            <FormItem
              name="currentIpStatus"
              label="Current IP status"
              rules={[{ required: true, message: "Field is required" }]}
            >
              <Select
                placeholder="Select an IP status"
                style={{ width: "100%" }}
                rules={[{ required: true, message: "Field is required" }]}
              >
                <Option value="1">Discussion</Option>
                <Option value="2">Document Review</Option>
                <Option value="3">Pre Planning Phase</Option>
                <Option value="4">Data Collection</Option>
                <Option value="5">Closed</Option>
              </Select>
            </FormItem>
          </div>
        </div>
        <div className="assignedToRoundLabel py-3">
          <span>Security plan</span>
          <Tooltip title="Information about assignment to rounds"></Tooltip>
        </div>
        <div className="row">
          <div className="column">
            <Form.Item name="files" label="Upload files">
              <Upload.Dragger
                multiple
                listType="text"
                accept=".jpg, .png"
                fileList={fileList}
                onChange={handleFileChange}
                beforeUpload={(file) => handleFileSize(file)}
              >
                <div className="d-flex justify-content-center pb-3">
                  <img src={uploadIcon} alt="" />
                </div>
                <Button className="uploadButton">
                  Drag and drop files here, or click to select files
                </Button>
                <div className="d-flex justify-content-center bottomText ">
                  <span className="span3">JPG or PNG</span>
                  <span className="span4">Max file size: 5MB</span>
                </div>
              </Upload.Dragger>

              <div className="uploadsContainer">
                {loadingFiles?.map((file) => (
                  <div className="uploadContentLoading" key={file.uid}>
                    <div className="d-flex flex-row gap-3">
                      <Progress
                        className="uploadIcon"
                        type="circle"
                        width={44}
                        percent={file.percent || 0}
                        format={(percent) =>
                          percent < 100 ? (
                            `${percent}%`
                          ) : (
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 48 }}
                                  spin
                                />
                              }
                            />
                          )
                        }
                      />
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row gap-2">
                          {file.percent < 100 && (
                            <h1
                              style={{ color: "red" }}
                              className="loading-text"
                            >
                              Loading file's
                              <span className="dots">
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                              </span>
                            </h1>
                          )}
                          {file.percent === 100 && (
                            <h1
                              style={{ color: "green" }}
                              className="loading-text"
                            >
                              Saving the file's to the server
                              <span className="dots">
                                <span>.</span>
                                <span>.</span>
                                <span>.</span>
                              </span>
                            </h1>
                          )}
                        </div>
                        {file.size && (
                          <p className="m-0 p-0">
                            {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                            MB
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {fileList &&
                  fileList.map((file) => (
                    <div className="uploadContentLoading" key={file.uid}>
                      <div className="d-flex flex-row gap-3">
                        <Progress
                          type="circle"
                          percent={100}
                          className="uploadIcon"
                          width={44}
                        />
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row gap-2">
                            <h1 className="loading-text">{file.name}</h1>
                          </div>
                          {file.size && (
                            <p className="m-0 p-0">
                              {(parseInt(file.size) / (1024 * 1024)).toFixed(1)}{" "}
                              MB
                            </p>
                          )}
                        </div>
                      </div>
                      <img
                        onClick={() => handleResourceRemove(file)}
                        src={RemoveIcon}
                        alt="RemoveIcon"
                      />
                    </div>
                  ))}
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="trackingElement">
          <Form.Item label="" className="trackingLabel"></Form.Item>
        </div>
      </Form>

      <Divider />
    </Modal>
  );
};

export default AddModal;
