import { Select, Input, Spin, Table, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import "../Reporting.scss";
import previewIcon from "./../../../../Assets/svg/table-action-preview.svg";
import { httpService } from "../../../../services/httpService.service";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";
import Preview from "./modals/Preview";
import Edit from "./modals/Edit";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import { formatDateAndTime } from "../../../../services/helpFunctions/formatDateAndTime";
import { useFilePreview } from "../../completed/ReportingList/Download";
import addIcon from "../../../../Assets/svg/plusIcon.svg";
import ConfirmModal from "../../../components/confirmModal/ConfirmModal";
import Done from "../../../../Assets/svg/done.svg";
import exclamationMark from "../../../../Assets/svg/exclamationMark.svg";

function ReportingList() {
  const { previewFiles } = useFilePreview();
  const [siteVisitCardsWaitingApproval, setSiteVisitCardsWaitingApproval] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [paginationForApproval, setPaginationForApproval] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [searchQueryForApproval, setSearchQueryForApproval] = useState("");
  const [debouncedQueryForApproval, setDebouncedQueryForApproval] = useState(
    searchQueryForApproval
  );
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageNumber: 1,
    }));
  };
  const handleSearchForApproval = (e) => {
    setSearchQueryForApproval(e.target.value);
    setPaginationForApproval((prevPagination) => ({
      ...prevPagination,
      pageNumber: 1,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQueryForApproval(searchQueryForApproval);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQueryForApproval]);

  const { Option } = Select;

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      includeOnlyWithTracking: true,
      Search: debouncedQuery,
      SiteVisitStatus: 5,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    const apiUrl = `/api/Site/getAllSiteVisits?${queryString}`;
    setLoading(true);
    httpService
      .get(
        apiUrl,
        (res) => {
          setSiteVisitCards(res.data);
          setPagination({
            pageSize: res.metaData.pageSize,
            pageNumber: res.metaData.pageNumber,
            total: res.metaData.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchSiteVisitsWaitingApproval = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      Search: debouncedQueryForApproval,
      pageSize: paginationForApproval.pageSize,
      pageNumber: paginationForApproval.pageNumber,
      ApprovalStatus: 1,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    const apiUrl = `/api/Site/getAllSiteVisits?${queryString}`;
    setLoading(true);
    httpService
      .get(
        apiUrl,
        (res) => {
          setSiteVisitCardsWaitingApproval(res.data);
          setPaginationForApproval({
            pageSize: res.metaData.pageSize,
            pageNumber: res.metaData.pageNumber,
            total: res.metaData.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSiteVisits();
  }, [pagination.pageNumber, pagination.pageSize, debouncedQuery]);

  useEffect(() => {
    fetchSiteVisitsWaitingApproval();
  }, [
    paginationForApproval.pageNumber,
    paginationForApproval.pageSize,
    debouncedQueryForApproval,
  ]);

  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleToggleEditModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowEditModal(!showEditModal);
  };

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site Visit Name</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Actual date of visit</span>,
      sorter: (a, b) => a.actualDateOfVisit.localeCompare(b.actualDateOfVisit),
      dataIndex: "actualDateOfVisit",
      key: "actualDateOfVisit",
      render: (text) => (
        <span className="styledDataIndex3">{formatDateAndTime(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Resources</span>,
      sorter: (a, b) =>
        a.resources?.join("").localeCompare(b.resources?.join("")),
      dataIndex: "resources",
      key: "resources",
      render: (resources) => {
        return (
          <div className="resourcesColumn">
            {resources?.map((file, index) => (
              <span
                onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                key={index}
              >
                {file?.fileName}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          {/* {tracked && ( */}
          <img
            src={previewIcon}
            onClick={() => handleTogglePreviewModal(record)}
            style={{ cursor: "pointer" }}
          ></img>
          {/* )} */}
          {/* {isAuthorized([
            RolesEnum.SystemAdmin,
            RolesEnum.ProjectManager,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldLogistics,
            RolesEnum.FieldTeamLeader,
          ]) && ( */}
          <Tooltip title="Add report">
            <img
              src={addIcon}
              onClick={() => handleToggleEditModal(record)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
          <Tooltip title="Submit to approve">
            <svg
              onClick={() => {
                ConfirmModal(
                  () => handleChangeConfirm(record?.key), // Pass the function as a callback
                  "Warning",
                  "Confirming this will send the site visit to submit approval! This action can not be undone!", // Information
                  "Do you want to continue?", // Question
                  "Yes" // Confirm button text
                );
                setLoading(false);
              }}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              stroke="#AA1A5F"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M6.85603 9.14565L0.570312 6.2885L15.4275 0.574219L9.71317 15.4314L6.85603 9.14565ZM6.85603 9.14565L10.2846 5.71708" />
            </svg>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleChangeConfirm = (SiteVisitId) => {
    if (SiteVisitId) {
      setLoading(true);
      httpService.put(
        `/api/Site/changeSiteVisitApprovalStatus?SiteVisitId=${SiteVisitId}&ApprovalStatus=1`,
        "",
        (res) => {
          const message =
            res?.message || "You have successfully updated site visit files!";
          setMessage({
            trigger: true,
            type: "success",
            icon: Done,
            title: `Site visit updated successfully!`,
            text: message,
          });

          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setLoading(false);
          fetchSiteVisits();
          fetchSiteVisitsWaitingApproval();
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message ||
            "Failed to update this site visit. Please try again later!";

          setMessage({
            trigger: true,
            type: "danger",
            icon: exclamationMark,
            title: "Update Failed",
            text: errorMessage,
          });

          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 3000);
          setLoading(false);
        }
      );
    }
  };

  const formatData = (siteVisitCard) => {
    return {
      key: siteVisitCard?.id,
      siteVisitIdentifier: siteVisitCard?.identifier,
      name: siteVisitCard?.name || "N/A",
      actualDateOfVisit: siteVisitCard?.tracking?.actualDateOfVisit,
      ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
      location: siteVisitCard?.locationDetails?.country,
      cluster: siteVisitCard?.cluster?.name,
      virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
      resources: siteVisitCard?.siteVisitFiles.filter((item) =>
        [1, 3, 4, 5].includes(item.fileCategory)
      ),

      reportingYear: siteVisitCard?.tracking?.reportingYear,
      reportingMonth: siteVisitCard?.tracking?.reportingMonth,
      trainingDate: siteVisitCard?.tracking?.trainingDate,
      trainingScoreInfo: siteVisitCard?.tracking?.trainingScoreInfo,
      dateOfReport: siteVisitCard?.tracking?.dateOfReport,
      dateOfDebrief: siteVisitCard?.tracking?.dateOfDebrief,
      percentageOfDataCollectionCompleted:
        siteVisitCard?.tracking?.percentageOfDataCollectionCompleted,
      fieldTeamReportingStatus:
        siteVisitCard?.tracking?.fieldTeamReportingStatus,
      trainingIsCompleted: siteVisitCard?.tracking?.trainingIsCompleted,
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  const formattedDataWaitingApproval =
    siteVisitCardsWaitingApproval?.map(formatData);

  const handleEdit = () => {
    fetchSiteVisits();

    setShowEditModal(false);
  };

  const columnsWaitingApproval = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site Visit Name</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Actual date of visit</span>,
      sorter: (a, b) => a.actualDateOfVisit.localeCompare(b.actualDateOfVisit),
      dataIndex: "actualDateOfVisit",
      key: "actualDateOfVisit",
      render: (text) => (
        <span className="styledDataIndex3">{formatDateAndTime(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Resources</span>,
      sorter: (a, b) =>
        a.resources?.join("").localeCompare(b.resources?.join("")),
      dataIndex: "resources",
      key: "resources",
      render: (resources) => {
        return (
          <div className="resourcesColumn">
            {resources?.map((file, index) => (
              <span
                onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                key={index}
              >
                {file?.fileName}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed:
        isLargeScreen && siteVisitCardsWaitingApproval.length > 0
          ? "right"
          : undefined,
      render: (text, record) => (
        <div
          className="actionDiv fitContentColumn"
          style={{ width: "fit-content" }}
        >
          <img
            src={previewIcon}
            onClick={() => handleTogglePreviewModal(record)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">Site Visit Reporting List</span>

        <div className="filtersWrapper">
          <div className="containerFilters">
            <Input
              onChange={handleSearch}
              placeholder="Search... "
              suffix={<img src={SearchIcon} alt="Search" />}
            />
          </div>
        </div>
        <div className="d-flex flex-column">
          <Table
            loading={loading}
            // rowSelection={{ ...rowSelection }}
            dataSource={formattedData}
            columns={columns}
            pagination={{
              ...pagination,
              onChange: (page, pageSize) => {
                setPagination({
                  ...pagination,
                  pageNumber: page,
                  pageSize: pageSize,
                });
              },
            }}
            className="tableTPM"
            scroll={{ x: "max-content" }}
          />
          <span className="tableViewHeaderText mt-5">
            Submitted Site Visits for Approval
          </span>
          <div className="filtersWrapper">
            <div className="containerFilters">
              <Input
                onChange={handleSearchForApproval}
                placeholder="Search... "
                suffix={<img src={SearchIcon} alt="Search" />}
              />
            </div>
          </div>
          <Table
            rowKey="id"
            dataSource={formattedDataWaitingApproval}
            columns={columnsWaitingApproval}
            loading={loading}
            className="tableTPM"
            pagination={{
              ...setPaginationForApproval,
              onChange: (page, pageSize) => {
                setPaginationForApproval({
                  ...paginationForApproval,
                  pageNumber: page,
                  pageSize: pageSize,
                });
              },
            }}
          />
        </div>
        <Preview
          onClose={handleTogglePreviewModal}
          visible={showPreviewModal}
          siteVisitCardId={selectedSiteVisit}
        />
        <Edit
          visible={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onOk={handleEdit}
          siteVisitCardId={selectedSiteVisit}
          setMessage={setMessage}
        />
      </div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
    </Spin>
  );
}

export default ReportingList;
