import React, { useState, useEffect } from "react";
import { Select, Radio, Spin, Progress, Empty } from "antd";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import "./TpmSummary.scss";
import iconCal from "../../../../Assets/Images/iconCalendar.png";
import iconCol1 from "../../../../Assets/Images/iconCollection1.png";
import iconCol2 from "../../../../Assets/Images/iconCollection2.png";
import snapshot1 from "../../../../Assets/svg/snapshot1.svg";
import snapshot2 from "../../../../Assets/svg/snapshot2.svg";
import snapshot3 from "../../../../Assets/svg/snapshot3.svg";
import snapshot4 from "../../../../Assets/svg/snapshot4.svg";
import snapshot5 from "../../../../Assets/svg/snapshot5.svg";
import snapshot6 from "../../../../Assets/svg/snapshot6.svg";
import location1 from "../../../../Assets/svg/location1.svg";
import location2 from "../../../../Assets/svg/location2.svg";
import location3 from "../../../../Assets/svg/location3.svg";
import location4 from "../../../../Assets/svg/location4.svg";
import location5 from "../../../../Assets/svg/location5.svg";
import location6 from "../../../../Assets/svg/location6.svg";
import WorldMap from "./WorldMap";
import { httpService } from "../../../../services/httpService.service";

const { Option } = Select;

function TpmSummary() {
  const [periodType, setPeriodType] = useState("Month");
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showGraphs, setShowGraphs] = useState(true);
  const siteVisitsColors = [
    "#FC9403",
    "#3B81FE",
    "#7B50A3",
    "#4EA38F",
    "#267920",
  ];
  const redFlagsColors = [
    "#FC9403",
    "#3B81FE",
    "#7B50A3",
    "#4EA38F",
    "#267920",
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const projectRoundName = localStorage.getItem("projectRoundName");
  const [selectedYear, setSelectedYear] = useState(null); // Default to null
  const [selectedMonth, setSelectedMonth] = useState(null); // Default to null
  const [selectedQuarter, setSelectedQuarter] = useState(null); // Default to null
  const currentMonth = months[new Date().getMonth()];
  const cMonth = new Date().getMonth() + 1;
  const currentQuarter = Math.ceil(cMonth / 3);

  useEffect(() => {
    const handleResize = () => {
      setShowGraphs(window.innerWidth >= 500);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchProjectData = async () => {
      setLoading(true);
      try {
        const projectID = localStorage.getItem("currentProjectID");
        if (projectID) {
          let apiProject;
          // Construct the API URL based on selected filters
          if (selectedYear && selectedMonth) {
            // If year and month are selected
            apiProject = `/api/Projects/getTPMsummary?projectId=${projectID}&year=${selectedYear}&month=${selectedMonth}`;
          } else if (selectedYear && selectedQuarter) {
            // If year and quarter are selected
            apiProject = `/api/Projects/getTPMsummary?projectId=${projectID}&year=${selectedYear}&Quarter=${selectedQuarter}`;
          } else if (selectedYear) {
            // If only year is selected
            apiProject = `/api/Projects/getTPMsummary?projectId=${projectID}&year=${selectedYear}`;
          } else {
            // If no filters selected, just project ID
            apiProject = `/api/Projects/getTPMsummary?projectId=${projectID}`;
          }

          httpService.get(
            apiProject,
            (data) => {
              setProjectData(data);
              setLoading(false);
            },
            (error) => {
              console.error("Error fetching project data:", error);
              setLoading(false);
            }
          );
        }
      } catch (error) {
        console.error("Error fetching project data:", error);
        setLoading(false);
      }
    };

    fetchProjectData();
  }, [selectedYear, selectedMonth, selectedQuarter]);

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  const handleQuarterChange = (value) => {
    setSelectedQuarter(value);
  };

  const handlePeriodTypeChange = (e) => {
    const value = e.target.value;
    setPeriodType(value);

    if (value === "Quarter") {
      setSelectedMonth(null); // Clear selected month when quarter is selected
    } else {
      setSelectedQuarter(null); // Clear selected quarter when month is selected
    }
  };

  // if (loading) {
  //   return <div className="w-100 p-5 justify-content-center"><Spin /></div>;
  // }

  const createPieData = (data, colors) => {
    const noData = data.every((item) => item.value === 0);

    if (noData) return null;

    return {
      labels: data.map((item) => item.type),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: colors,
          hoverBackgroundColor: colors,
        },
      ],
    };
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    cutout: "70%",
  };

  const siteVisitsData = [
    {
      type: "Data Check",
      value: projectData?.data?.siteVisitsProgress?.dataCheck,
    },
    {
      type: "Data Collection",
      value: projectData?.data?.siteVisitsProgress?.dataCollection,
    },
    {
      type: "Planning",
      value: projectData?.data?.siteVisitsProgress?.planning,
    },
    {
      type: "Pre-Planning Phase",
      value: projectData?.data?.siteVisitsProgress?.prePlanningPhase,
    },
    {
      type: "Visit Completed",
      value: projectData?.data?.siteVisitsProgress?.visitCompleted,
    },
  ];

  const redFlagsData = [
    {
      type: "Archived",
      value: projectData?.data?.redFlagsProgress?.archived,
    },
    {
      type: "Assigned",
      value: projectData?.data?.redFlagsProgress?.assigned,
    },
    {
      type: "In Progress",
      value: projectData?.data?.redFlagsProgress?.inprogress,
    },
    {
      type: "On Hold",
      value: projectData?.data?.redFlagsProgress?.onhold,
    },
    {
      type: "Resolved",
      value: projectData?.data?.redFlagsProgress?.resolved,
    },
  ];

  const tasksData = [
    {
      type: "Accepted Tasks",
      value: projectData?.data?.tasksOverviewResponse?.acceptedTasks,
    },
    {
      type: "Archived Tasks",
      value: projectData?.data?.tasksOverviewResponse?.archivedTasks,
    },
    {
      type: "Assigned Tasks",
      value: projectData?.data?.tasksOverviewResponse?.assignedTasks,
    },
    {
      type: "Completed Tasks",
      value: projectData?.data?.tasksOverviewResponse?.completedTasks,
    },
    {
      type: "On Hold",
      value: projectData?.data?.tasksOverviewResponse?.onHoldTasks,
    },
  ];

  const years = [];
  for (let year = 2024; year <= 2030; year++) {
    years.push(
      <Option key={year} value={year}>
        {year}
      </Option>
    );
  }

  const monthOptions = months.map((month, index) => (
    <Option key={index} value={month}>
      {month}
    </Option>
  ));

  return (
    <Spin spinning={loading}>
      <div className="tpmSummaryDiv">
        <div className="tpmSummaryHeader">
          <Select
            placeholder="Select year"
            style={{ width: 200 }}
            onChange={handleYearChange}
            allowClear
          >
            {years}
          </Select>
          {periodType === "Quarter" ? (
            <Select
              placeholder="Select Quarter"
              value={selectedQuarter}
              style={{ width: 200 }}
              onChange={handleQuarterChange}
              allowClear
              disabled={!selectedYear}
            >
              <Select.Option key={1} value={1}>
                Q1 (January - March)
              </Select.Option>
              <Select.Option key={2} value={2}>
                Q2 (April - June)
              </Select.Option>
              <Select.Option key={3} value={3}>
                Q3 (July - September)
              </Select.Option>
              <Select.Option key={4} value={4}>
                Q4 (October - December)
              </Select.Option>
            </Select>
          ) : (
            <Select
              value={selectedMonth}
              style={{ width: 200 }}
              onChange={handleMonthChange}
              placeholder="Select Month"
              allowClear
              disabled={!selectedYear}
            >
              {monthOptions}
            </Select>
          )}
          <Radio.Group
            onChange={handlePeriodTypeChange}
            value={periodType}
            className="custom-radio-group"
          >
            <Radio.Button value="Month" className="custom-radio-button">
              Month
            </Radio.Button>
            <Radio.Button value="Quarter" className="custom-radio-button">
              Quarter
            </Radio.Button>
          </Radio.Group>
        </div>
        {showGraphs && (
          <div className="tpmSummaryProject">
            <div className="tpmSummaryProjectName">
              <img src={iconCal} alt="img" />
              {projectRoundName}
              {selectedYear && ` - ${selectedYear}`}
              {selectedMonth && ` - ${selectedMonth}`}
              {selectedQuarter && ` - Q${selectedQuarter}`}
            </div>
            <div className="tpmSummaryProjectInfo">
              <div className="tpmSummaryCollectionGoal">
                <div className="collectionGoalHeader">
                  <img src={iconCol1} alt="img" /> Site visits progress
                </div>
                <div className="collectionGoalProgress">
                  <center>
                    {loading ? (
                      <div>Loading...</div>
                    ) : createPieData(siteVisitsData, siteVisitsColors) ? (
                      <div style={{ maxWidth: "400px", width: "100%" }}>
                        <Pie
                          data={createPieData(siteVisitsData, siteVisitsColors)}
                          options={pieOptions}
                        />
                      </div>
                    ) : (
                      <Empty description="No data available" />
                    )}
                  </center>
                </div>
              </div>
              <div className="tpmSummaryCollectionGoal">
                <div className="collectionGoalHeader">
                  <img src={iconCol2} alt="img" /> Red flags progress
                </div>
                <div className="collectionGoalProgress">
                  <center>
                    {loading ? (
                      <div>Loading...</div>
                    ) : createPieData(redFlagsData, redFlagsColors) ? (
                      <div style={{ maxWidth: "400px", width: "100%" }}>
                        <Pie
                          data={createPieData(redFlagsData, redFlagsColors)}
                          options={pieOptions}
                        />
                      </div>
                    ) : (
                      <Empty description="No data available" />
                    )}
                  </center>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">Activity Snapshot</h2>
          <div className="activitySnapshotResultCard">
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeIpPlanningCards}
                </h2>
                <p className="activitySnapshotText">Active IP planning cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot1} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeRedFlagCards}
                </h2>
                <p className="activitySnapshotText">Active Red flag cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot2} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.projectMembers}
                </h2>
                <p className="activitySnapshotText">Project members</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot3} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activitySnapshot
                      ?.activeEnumeratorTeamCards
                  }
                </h2>
                <p className="activitySnapshotText">
                  Active enumerator team cards
                  <br />
                </p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot4} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeSiteVisitCards}
                </h2>
                <p className="activitySnapshotText">Active site visit cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot5} alt="img" />
              </div>
            </div>
            <div className="activitySnapshotCard">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activitySnapshot?.activeTripCards}
                </h2>
                <p className="activitySnapshotText">Active trip cards</p>
              </div>
              <div className="activitySnapshotImg">
                <img src={snapshot6} alt="img" />
              </div>
            </div>
          </div>
        </div>
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">Activity by Location</h2>
          <div className="activitySnapshotResultCard">
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activityByLocation?.plannedVisits}
                </h2>
                <p className="activitySnapshotText">Planned visits</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location1} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activityByLocation?.postponedCards}
                </h2>
                <p className="activitySnapshotText">Postponed cards</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location2} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {projectData?.data?.activityByLocation?.completedVisits}
                </h2>
                <p className="activitySnapshotText">Completed visits</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location3} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activityByLocation
                      ?.completedBeneficiaryInterviews
                  }{" "}
                  <span style={{ fontSize: " 20px" }}>completed</span>
                </h2>
                <p className="activitySnapshotText">Beneficiary interviews</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location4} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activityByLocation
                      ?.completedClientsInterviews
                  }
                  <span style={{ fontSize: " 20px" }}>completed</span>
                </h2>
                <p className="activitySnapshotText">clients interviews</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location5} alt="img"></img>
              </div>
            </div>
            <div className="activitySnapshotCard1">
              <div className="activitySnapshotNumber">
                <h2 className="activitySnapshotNumber1">
                  {
                    projectData?.data?.activityByLocation
                      ?.completedObservationInterviews
                  }
                  <span style={{ fontSize: " 20px" }}>completed</span>
                </h2>
                <p className="activitySnapshotText">Observation checklist</p>
              </div>
              <div className="activitySnapshotImg1">
                <img src={location6} alt="img"></img>
              </div>
            </div>
          </div>
          <WorldMap></WorldMap>
        </div>
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">
            IP/Donor Reporting Status Snapshot
          </h2>
          <div className="donorsDiv">
            <div className="donorsLeft">
              <div className="donorsCardsContainer">
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {" "}
                      {
                        projectData?.data?.ipDonorReporting
                          ?.completedFieldTeamReporting
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      Field team reports-Completed
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location1} alt="img"></img>
                  </div>
                </div>{" "}
                {/* <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {
                        projectData?.data?.ipDonorReporting
                          ?.awaitingFieldReportings
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      IP/Donor reports - Awaiting Field
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location2} alt="img"></img>
                  </div>
                </div>{" "} */}
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {
                        projectData?.data?.ipDonorReporting
                          ?.otherStatusesFieldTeamReporting
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      Field team reports-Other statuses
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location5} alt="img"></img>
                  </div>
                </div>{" "}
                <div className="donorsCard">
                  {" "}
                  <div className="activitySnapshotNumber">
                    <h2 className="activitySnapshotNumber1">
                      {
                        projectData?.data?.ipDonorReporting
                          ?.completedIPDonorReports
                      }
                    </h2>
                    <p className="activitySnapshotText">
                      IP/Donor reports - Completed
                    </p>
                  </div>
                  <div className="activitySnapshotImg1">
                    <img src={location6} alt="img"></img>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="donorsRight">
              {" "}
              <div className="donorListLeft">
                <h1 className="donorListHeaderText">IP/Donor reports</h1>
              </div>
              <div className="donorListRight">
                <h2 className="donorListHeader2Text">Last 3 uploaded</h2>
              </div>
              <ul>
                {" "}
                {/* Assuming this is a list of reports */}
                {projectData?.data?.ipDonorReporting?.ipDonorReports.map(
                  (report, index) => (
                    <li key={index} className="donorListItem">
                      IP/Donor Report - {report.ipName} - {report.location} -
                      Report {report.reportNumber}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="activitySnapshot">
          <h2 className="activitySnapshotHeader">Tasks overview</h2>
          <div className="donorsDiv">
            {" "}
            {showGraphs && (
              <div className="tpmSummaryCollectionGoal">
                <div className="collectionGoalHeader">
                  <img src={iconCol1} alt="img" /> Tasks
                </div>
                <div className="collectionGoalProgress">
                  <center>
                    {loading ? (
                      <div>Loading...</div>
                    ) : createPieData(tasksData, siteVisitsColors) ? (
                      <div style={{ maxWidth: "400px", width: "100%" }}>
                        <Pie
                          data={createPieData(tasksData, siteVisitsColors)}
                          options={pieOptions}
                        />
                      </div>
                    ) : (
                      <Empty description="No data available" />
                    )}
                  </center>
                </div>
              </div>
            )}
            <div className="taskRight">
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.assignedTasks}
                  </h2>
                  <p className="activitySnapshotText">Assigned tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot1} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.acceptedTasks}
                  </h2>
                  <p className="activitySnapshotText">Accepted tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot1} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.onHoldTasks}
                  </h2>
                  <p className="activitySnapshotText">On hold tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot1} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.archivedTasks}
                  </h2>
                  <p className="activitySnapshotText">Archived tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot3} alt="img"></img>
                </div>
              </div>
              <div className="activityTaskCard">
                <div className="activitySnapshotNumber">
                  <h2 className="activitySnapshotNumber1">
                    {projectData?.data?.tasksOverviewResponse?.completedTasks}
                  </h2>
                  <p className="activitySnapshotText">Completed tasks</p>
                </div>
                <div className="activitySnapshotImg">
                  <img src={snapshot5} alt="img"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default TpmSummary;
