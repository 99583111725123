// ConfirmModal.js
import React from "react";
import { Modal } from "antd";
import Warning from "../../../Assets/svg/warning.svg";
import "./style.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";

const { confirm } = Modal;

const ConfirmModal = (onConfirm, title, info, question, confirmButtonText) => {
  confirm({
    title: title,
    icon: (
      <img
        src={Warning}
        alt="Warning"
        style={{ width: "30px", height: "30px", flexShrink: "0" }}
      />
    ),
    content: (
      <p className="d-flex flex-column align-items-center">
        {info}
        <div>
          <span style={{ fontWeight: "700", color: "#3A3737" }}>
            {question}
          </span>
        </div>
      </p>
    ),
    okText: confirmButtonText,
    cancelText: "No",
    okButtonProps: {
      className: "addButtonTPM",
    },
    cancelButtonProps: {
      className: "inviteButtonTPM",
    },
    closeIcon: (
      <img src={cancelX} style={{ marginLeft: "auto", cursor: "pointer" }} />
    ),
    onOk: onConfirm,
    onCancel: () => {},
  });
};

export default ConfirmModal;
