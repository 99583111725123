import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Spin, Tooltip } from "antd";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { httpService } from "../../../../services/httpService.service";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";
import completedIcon from "../../../../Assets/svg/completedIcon.svg";
import inProgressIcon from "../../../../Assets/svg/inProgressIcon.svg";
import notInitatedIcon from "../../../../Assets/svg/notInitatedIcon.svg";

const createIcon = (status) => {
  let icon;
  let color;

  if (status == "completed") {
    icon = completedIcon;
    color = "#B6CE84";
  } else if (status.toLowerCase() == "InProgress".toLowerCase()) {
    icon = inProgressIcon;
    color = "#FFD88C";
  } else if (status.toLowerCase() == "notInitiated".toLowerCase()) {
    icon = notInitatedIcon;
    color = "#999";
  } else if (status.toLowerCase() == "postponed".toLowerCase()) {
    icon = notInitatedIcon;
    color = "#999";
  }
  return L.divIcon({
    className: "custom-icon",
    html: `<div style="
      width: 40px; 
      height: 40px; 
      border-radius: 50%; 
      background: ${color}; 
      display: flex; 
      align-items: center; 
      justify-content: center;
      box-shadow: 0px 4px 12px rgba(59, 75, 104, 0.25);
      font-size: 16px;
      font-weight: bold;
      color: #fff;
    "><img src=${icon}/></div>`,
    iconSize: [40, 40],
  });
};

const Map = ({ data, loadingData }) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);

  const handleMarkerClick = (siteVisitCardId) => {
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(true);
  };

  const handleClosePreviewModal = () => {
    setShowPreviewModal(false);
    setSelectedSiteVisit(null);
  };

  const adjustMarkerPosition = (markers) => {
    const grouped = {};
    const adjustedMarkers = [];

    markers.forEach((marker) => {
      const key = `${marker.latitude},${marker.longitude}`;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(marker);
    });

    Object.values(grouped).forEach((group) => {
      if (group.length === 1) {
        adjustedMarkers.push(group[0]);
      } else {
        const angleStep = (2 * Math.PI) / group.length;
        group.forEach((marker, index) => {
          const angle = index * angleStep;
          const offsetLat = 0.0001 * Math.cos(angle);
          const offsetLng = 0.0001 * Math.sin(angle);
          adjustedMarkers.push({
            ...marker,
            latitude: marker.latitude + offsetLat,
            longitude: marker.longitude + offsetLng,
          });
        });
      }
    });

    return adjustedMarkers;
  };

  const adjustedData = adjustMarkerPosition(data);

  return (
    <>
      <div className="ipCardDiv">
        <div style={{ position: "relative", height: "450px", width: "100%" }}>
          {loadingData && (
            <Spin
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
              }}
            />
          )}
          <MapContainer
            center={[20, 0]}
            zoom={2}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {adjustedData.map((item) => (
              <Marker
                key={item.id}
                position={[item.latitude, item.longitude]}
                icon={createIcon(item.status)}
              >
                <Tooltip direction="top" offset={[0, -40]} opacity={1}>
                  {item.name}
                </Tooltip>
              </Marker>
            ))}
          </MapContainer>
        </div>
      </div>
      <Preview
        visible={showPreviewModal}
        onClose={handleClosePreviewModal}
        siteVisitCardId={selectedSiteVisit}
      />
    </>
  );
};

export default Map;
