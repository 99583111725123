import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from "antd";
import { useState } from "react";

const { Option } = Select;

//months
const months = [
  {
    id: "01",
    name: "January",
  },
  {
    id: "02",
    name: "February",
  },
  {
    id: "03",
    name: "March",
  },
  {
    id: "04",
    name: "April",
  },
  {
    id: "05",
    name: "May",
  },
  {
    id: "06",
    name: "June",
  },
  {
    id: "07",
    name: "July",
  },
  {
    id: "08",
    name: "August",
  },
  {
    id: "09",
    name: "September",
  },
  {
    id: "10",
    name: "October",
  },
  {
    id: "11",
    name: "November",
  },
  {
    id: "12",
    name: "December",
  },
];

//years
const startYear = 2024;
const endYear = 2034;
const years = Array.from(
  { length: endYear - startYear + 1 },
  (_, i) => startYear + i
);

const TrackingDetails = ({
  form,
  enumerators,
  setEnumerators,
  siteVisitStatus,
  setSiteVisitStatus,
  treningCompleted,
  setTreningCompleted,
  reportingStatusOptions,
  setReportingStatusOptions,
  dateFields,
  setDateFields,
}) => {
  const addDateField = () => {
    setDateFields([...dateFields, dateFields.length]);
  };

  const removeDateField = (index) => {
    setDateFields(dateFields.filter((_, i) => i !== index));
  };
  const handleScoreChange = (id, value) => {
    setEnumerators((prevEnumerators) =>
      prevEnumerators.map((enumerator) =>
        enumerator.enumeratorId == id
          ? { ...enumerator, trainingScore: value ? parseFloat(value) : "" }
          : enumerator
      )
    );
  };

  const handlePercentageChange = (id, value) => {
    console.log("tesstid:", id);
    setEnumerators((prevEnumerators) =>
      prevEnumerators.map((enumerator) =>
        enumerator.enumeratorId == id
          ? {
              ...enumerator,
              percentageCompleted: value ? parseFloat(value) : "",
            }
          : enumerator
      )
    );
  };

  const handleSiteVisitStatusChange = (value) => {
    form.setFieldsValue({ fieldTeamReportingStatus: undefined });
    setSiteVisitStatus(value);
    let options = [];
    if (value === 4) {
      options = [{ value: 1, label: "Data collection" }];
    } else if (value === 5) {
      options = [
        { value: 2, label: "Data check" },
        { value: 3, label: "Field report writing" },
        { value: 4, label: "Translation" },
        { value: 5, label: "Field reporting completed" },
        { value: 6, label: "Field report approved" },
      ];
    } else if (value === 6) {
      options = [{ value: 7, label: "Field report submitted to client" }];
    }
    setReportingStatusOptions(options);
  };

  return (
    <div>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="reportingYear" label="Reporting year" required>
            <Select placeholder="Select reporting year" showSearch>
              {years.map((year, index) => (
                <Option key={index} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="reportingMonth" label="Reporting month" required>
            <Select placeholder="Select reporting month" showSearch>
              {months.map((month) => (
                <Option key={month.id} value={month.id}>
                  {month.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Training Completed"}
            name="trainingIsCompleted"
            valuePropName="checked"
          >
            <Switch
              checked={treningCompleted}
              onChange={(checked) => setTreningCompleted(checked)}
            />
          </Form.Item>
        </Col>
      </Row>
      {dateFields.map((field, index) => (
        <div
          key={field}
          className="trainingDateDiv"
          style={{ marginBottom: "16px" }}
        >
          <Form.Item
            name={`trainingDate${field}`}
            label={`Training Date ${index + 1}`}
            required
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>

          {index > 0 && (
            <Button
              danger
              icon={<MinusOutlined />}
              onClick={() => removeDateField(index)}
              style={{ marginTop: "8px" }}
            />
          )}
        </div>
      ))}

      {/* Add button outside the div */}
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={addDateField}
        className="addButtonTPM"
        style={{ marginTop: "16px", marginBottom: "16px", height: "36px" }}
      >
        Add Training Date
      </Button>
      {treningCompleted && (
        <div className="d-flex flex-column gap-3 mb-5 mt-4">
          <h3 className="m-0 p-0">Training scores</h3>
          {enumerators?.map((enumerator, index) => (
            <div
              key={enumerator?.enumeratorId}
              className="d-flex justify-content-between align-items-center w-100"
              style={{
                padding: "10px 16px",
                alignSelf: "stretch",
                borderRadius: "8px",
                border: "1px solid var(--5, #F5B0D2)",
                background: "var(--7, #FFF5FA)",
              }}
            >
              <div className="d-flex justify-content-start align-items-center h-100 w-100">
                <p
                  className="m-0"
                  style={{
                    color: "var(--Neutrals-Black, #3A3737)",
                    fontFamily: "Lato",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "22px",
                  }}
                >
                  {enumerator?.firstName && enumerator?.lastName
                    ? `${enumerator.firstName} ${enumerator.lastName}`
                    : enumerator?.email}
                </p>
              </div>

              <Form.Item
                name={`trainingScore-${index}`}
                className="m-0 p-0"
                label="Training score"
                style={{ width: "178px" }}
              >
                <Input
                  placeholder="1"
                  min={0}
                  type="number"
                  value={parseInt(enumerator?.trainingScore)}
                  onChange={(e) => {
                    handleScoreChange(enumerator.enumeratorId, e.target.value);
                  }}
                />
              </Form.Item>
            </div>
          ))}
        </div>
      )}

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="actualDateOfVisit"
            label="Actual date of visit"
            required
          >
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="siteVisitStatus" label="Site Visit Status" required>
            <Select
              placeholder="Select status"
              onChange={handleSiteVisitStatusChange}
            >
              <Option value={4}>Data collection</Option>
              <Option value={5}>Post data collection/Data check</Option>
              <Option value={6}>Visit completed</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      {siteVisitStatus === 5 && (
        <div className="d-flex flex-column gap-3 mb-5 mt-4">
          <h3 className="m-0 p-0">% of data collection completed</h3>
          {enumerators?.map((enumerator, index) => (
            <div
              key={enumerator?.enumeratorId}
              className="d-flex justify-content-between align-items-center w-100"
              style={{
                padding: "10px 16px",
                alignSelf: "stretch",
                borderRadius: "8px",
                border: "1px solid var(--5, #F5B0D2)",
                background: "var(--7, #FFF5FA)",
              }}
            >
              <div className="d-flex justify-content-start align-items-center h-100 w-100">
                <p
                  className="m-0"
                  style={{
                    color: "var(--Neutrals-Black, #3A3737)",
                    fontFamily: "Lato",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "22px",
                  }}
                >
                  {enumerator?.firstName && enumerator?.lastName
                    ? `${enumerator.firstName} ${enumerator.lastName}`
                    : enumerator?.email}
                </p>
              </div>

              <Form.Item
                name={`percentageCompleted-${index}`}
                className="m-0 p-0"
                label="% Completed"
                style={{ width: "178px" }}
              >
                <Input
                  placeholder="1"
                  min={0}
                  type="number"
                  value={parseInt(enumerator?.percentageCompleted)}
                  onChange={(e) => {
                    handlePercentageChange(
                      enumerator.enumeratorId,
                      e.target.value
                    );
                  }}
                />
              </Form.Item>
            </div>
          ))}
        </div>
      )}

      <Row gutter={24}></Row>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="dateOfReport" label="Date of report" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="dateOfDebrief" label="Date of debrief" required>
            <DatePicker style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="fieldTeamReportingStatus"
            label="Reporting Status"
            required
          >
            <Select placeholder="Select a status">
              {reportingStatusOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
export default TrackingDetails;
