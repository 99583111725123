import React, { useState } from "react";
import HorizontalBarChart from "./Accessibility";
import VisitsPlanningExecution from "./VisitsPlanningExecution";
import GeographicsTrends from "./GeographicsTrends";
import "./VisitDataSummary.scss";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import Accessibility from "./Accessibility";
import IPAnalysis from "./IPAnalysis";
import TrainingAnalysis from "./TrainingAnalysis";

const VisitDataSummary = ({ countryOfProjectId }) => {
  const sections = [
    {
      title: "Visits Planning & Execution",
      chart: (
        <VisitsPlanningExecution countryOfProjectId={countryOfProjectId} />
      ),
    },
    { title: "Accessibility Insights", chart: <Accessibility /> },
    { title: "Geographic Trends", chart: <GeographicsTrends /> },
    { title: "Implementing Partner (IP) Analysis", chart: <IPAnalysis /> },
    { title: "Training Analysis", chart: <TrainingAnalysis /> },
  ];

  const [detailsVisibility, setDetailsVisibility] = useState(
    sections.map(() => true)
  );

  const toggleDetailsVisibility = (e, index) => {
    e.preventDefault();
    setDetailsVisibility((prevState) =>
      prevState.map((isVisible, i) => (i === index ? !isVisible : isVisible))
    );
  };

  return (
    <div className="VisitDataSummaryContainer w-100">
      <div className="containerOfVisits w-100">
        {sections.map((section, index) => (
          <div className="siteVisit w-100" key={index}>
            <div
              className={`siteVisitTitle ${
                detailsVisibility[index] ? "visable" : ""
              }`}
              onClick={(e) => toggleDetailsVisibility(e, index)}
              style={{ cursor: "pointer" }}
            >
              <p>{section.title}</p>
              {detailsVisibility[index] ? (
                <ArrowUpOutlined />
              ) : (
                <ArrowDownOutlined />
              )}
            </div>
            {detailsVisibility[index] && (
              <div className="siteVisitDetails w-100">
                <div className="chartsContainer">
                  <div
                    className="chart"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "200px",
                      minHeight: "300px",
                      padding: "24px",
                      gap: "24px",
                    }}
                  >
                    {section.chart}
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VisitDataSummary;
