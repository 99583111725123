import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import FieldTeamStatusBoard from "./FieldTeamStatusBoard/FieldTeamStatusBoard";
import IpDonorStatusBoard from "./IpDonorStatusBoard/IpDonorStatusBoard";
import ReportingList from "./ReportingList/ReportingList";
import ApproveSiteVisits from "./approveSiteVisits/ApproveSiteVisits";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import { RolesEnum } from "../../../services/rolesEnum";
import { isAuthorized } from "../../../services/authService";

const VALID_TABS = [
  "FieldTeamStatusBoard",
  "IpDonorStatusBoard",
  "ReportingList",
  "ApproveSiteVisits",
];

function Reporting() {
  const [activeComponent, setActiveComponent] = useState(
    "FieldTeamStatusBoard"
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  const getDefaultActiveKey = () => {
    const params = new URLSearchParams(location.search);
    const activeKey = params.entries().next().value?.[0];
    return VALID_TABS.includes(activeKey) ? activeKey : "FieldTeamStatusBoard";
  };

  useEffect(() => {
    const defaultTab = getDefaultActiveKey();
    setActiveComponent(defaultTab);
  }, [location.search]);

  const handleTabChange = (key) => {
    if (VALID_TABS.includes(key)) {
      navigate(`/project/reporting?${key}`);
    } else {
      navigate("/project/reporting");
    }
  };

  const renderComponent = () => {
    if (activeComponent === "FieldTeamStatusBoard") {
      return (
        <div>
          <FieldTeamStatusBoard />
        </div>
      );
    } else if (activeComponent === "IpDonorStatusBoard") {
      return (
        <div>
          <IpDonorStatusBoard />
        </div>
      );
    } else if (activeComponent === "ReportingList") {
      return (
        <div>
          <ReportingList />
        </div>
      );
    } else if (activeComponent === "ApproveSiteVisits") {
      return (
        <div>
          <ApproveSiteVisits />
        </div>
      );
    }
  };

  return (
    <div className="implementationPage">
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Banner
        text={`${localStorage.getItem("projectRoundName")} - Reporting`}
      />
      <div style={{ background: "#F6F6F6", paddingTop: "60px" }}>
        <div
          className="tab-container"
          style={{
            borderTop: "2px solid #DFE6FF",
            borderBottom: "none",
            background: "white",
          }}
        >
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <div>
              <label
                className={
                  activeComponent === "FieldTeamStatusBoard"
                    ? "tab-nav-active"
                    : "tab-nav-not-active"
                }
              >
                <input
                  type="radio"
                  value="FieldTeamStatusBoard"
                  checked={activeComponent === "FieldTeamStatusBoard"}
                  onChange={() => handleTabChange("FieldTeamStatusBoard")}
                />
                Field Team Status Board
              </label>
            </div>
          )}

          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldEnumerator,
            RolesEnum.FieldLogistics,
          ]) && (
            <div>
              <label
                className={
                  activeComponent === "IpDonorStatusBoard"
                    ? "tab-nav-active"
                    : "tab-nav-not-active"
                }
              >
                <input
                  type="radio"
                  value="IpDonorStatusBoard"
                  checked={activeComponent === "IpDonorStatusBoard"}
                  onChange={() => handleTabChange("IpDonorStatusBoard")}
                />
                IP/Donor Status Board
              </label>
            </div>
          )}
          {isAuthorized([
            RolesEnum.ProjectManager,
            RolesEnum.SystemAdmin,
            RolesEnum.FieldCoordinator,
            RolesEnum.FieldTeamLeader,
            RolesEnum.FieldLogistics,
          ]) && (
            <div>
              <label
                className={
                  activeComponent === "ReportingList"
                    ? "tab-nav-active"
                    : "tab-nav-not-active"
                }
              >
                <input
                  type="radio"
                  value="ReportingList"
                  checked={activeComponent === "ReportingList"}
                  onChange={() => handleTabChange("ReportingList")}
                />
                Visit Board Reporting List
              </label>
            </div>
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <div>
              <label
                className={
                  activeComponent === "ApproveSiteVisits"
                    ? "tab-nav-active"
                    : "tab-nav-not-active"
                }
              >
                <input
                  type="radio"
                  value="ApproveSiteVisits"
                  checked={activeComponent === "ApproveSiteVisits"}
                  onChange={() => handleTabChange("ApproveSiteVisits")}
                />
                Approve Site Visits
              </label>
            </div>
          )}
        </div>
      </div>
      <div>{renderComponent()}</div>
    </div>
  );
}

export default Reporting;
