import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  Spin,
  Checkbox,
  Tooltip,
  Divider,
  Card,
} from "antd";
import {
  MapContainer,
  TileLayer,
  Marker,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { AimOutlined } from "@ant-design/icons";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from "leaflet";
import { OpenStreetMapAutocomplete } from "@amraneze/osm-autocomplete";
import "./LocationDetails.scss";
import axios from "axios";
import DownArrow from "../../../../../Assets/svg/downArrow.svg";
import { debounce } from "lodash";

const LocationMarker = ({ position, setPosition, form }) => {
  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      form.setFieldsValue({
        latitude: lat.toFixed(6),
        longitude: lng.toFixed(6),
      });
    },
  });

  return position ? (
    <Marker
      position={position}
      icon={
        new Icon({
          iconUrl: markerIconPng,
          iconSize: [25, 41],
          iconAnchor: [12, 41],
        })
      }
    />
  ) : null;
};

const MapWithInvalidateSize = ({ children }) => {
  const map = useMap();

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize();
    }, 100);
  }, [map]);

  return <>{children}</>;
};

const MapCenterUpdater = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position && position[0] !== null && position[1] !== null) {
      map.setView(position, map.getZoom(), {
        animate: true,
      });
    }
  }, [position, map]);

  return null;
};

const LocationDetails = ({
  form,
  address,
  setAddress,
  isEditing,
  parishCheck,
  setParishCheck,
  selectedIpPlan,
  showHideIpPlanDetails,
  setShowHideIpPlanDetails,
}) => {
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);
  const [position, setPosition] = useState([0.0, 0.0]);
  const [loading, setLoading] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(isEditing ? 13 : 2);
  const [mapLoading, setMapLoading] = useState(false);
  const lat = form.getFieldValue("latitude");
  const lon = form.getFieldValue("longitude");

  const seeIpPlanDetails = () => {
    setShowHideIpPlanDetails(!showHideIpPlanDetails);
  };

  useEffect(() => {
    const lat = form.getFieldValue("latitude");
    const lng = form.getFieldValue("longitude");
    map(lat, lng);
    if (isEditing && lat && lon) {
      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);
      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
        setPosition([parsedLat, parsedLon]);
      }
      setMapLoading(false); // Data has been loaded
    }
  }, [form, isEditing, lat, lon]);

  useEffect(() => {
    if (!isNaN(latitude) && !isNaN(longitude)) {
      setPosition([latitude, longitude]);
    } else {
      setPosition([0, 0]); // Default to a neutral position if invalid
    }
  }, [latitude, longitude]);

  const map = (lat, lng) => {
    if (mapLoading) return; // Prevent API call if already loading

    setMapLoading(true); // Set loading to true before API call
    axios
      .get(`https://nominatim.openstreetmap.org/reverse`, {
        params: {
          lat: lat,
          lon: lng,
          format: "json",
        },
      })
      .then((response) => {
        const responseAddress = response?.data?.address;
        const formattedAddress = {
          Latitude: lat,
          Longitude: lng,
          ...(responseAddress?.house_number && {
            houseNumber: responseAddress.house_number,
          }),
          ...(responseAddress?.road && { road: responseAddress.road }),
          ...(responseAddress?.city && { city: responseAddress.city }),
          ...(responseAddress?.municipality && {
            municipality: responseAddress.municipality,
          }),
          ...(responseAddress?.district && {
            district: responseAddress.district,
          }),
          ...(responseAddress?.county &&
            !responseAddress?.district && { district: responseAddress.county }),
          ...(responseAddress?.postcode && {
            postcode: responseAddress.postcode,
          }),
          ...(responseAddress?.country && { country: responseAddress.country }),
          ...(responseAddress?.country_code && {
            countryCode: responseAddress.country_code,
          }),
          ...(responseAddress?.municipality && {
            parish: `${response.data.display_name}, Parish: ${responseAddress.municipality}`,
          }),
          ...(!responseAddress?.municipality &&
            response?.data?.display_name && {
              parish: response.data.display_name,
            }),
        };

        setAddress(formattedAddress);
      })
      .catch((err) => {
        console.error(err);
        setAddress("Failed to get address");
      })
      .finally(() => setMapLoading(false));
  };

  useEffect(() => {
    const handleValuesChange = () => {
      const lat = form.getFieldValue("latitude");
      const lon = form.getFieldValue("longitude");

      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);
      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
      } else {
        setPosition([0, 0]); // Reset position if values are invalid
      }
      setLoading(false); // Data has been loaded
    };

    form.onValuesChange = handleValuesChange;

    return () => {
      form.onValuesChange = () => {}; // Cleanup
    };
  }, [form]);

  const handleGetCurrentLocation = () => {
    setMapLoading(true);
    setZoomLevel(13);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude, accuracy } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          setPosition([latitude, longitude]);
          form.setFieldsValue({
            latitude: latitude.toFixed(6),
            longitude: longitude.toFixed(6),
            accuracy: accuracy.toFixed(2),
          });
          // Set zoom level to 13 when current location is set
          setLoading(false); // Data has been loaded
          setMapLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false); // Ensure loading is turned off even if there's an error
          setMapLoading(false);
        }
      );
    } else {
      setLoading(false); // Ensure loading is turned off even if geolocation is not supported
      setMapLoading(false);
    }
  };

  const selectLocation = (data) => {
    const { lat, lon } = data;
    if (data) {
      const parsedLat = parseFloat(lat);
      const parsedLon = parseFloat(lon);
      if (!isNaN(parsedLat) && !isNaN(parsedLon)) {
        setLatitude(parsedLat);
        setLongitude(parsedLon);
        setPosition([parsedLat, parsedLon]);
        form.setFieldsValue({
          latitude: parsedLat.toFixed(6),
          longitude: parsedLon.toFixed(6),
          accuracy: 0,
        });
        setZoomLevel(13); // Optionally set zoom level to 13 on search
        setLoading(false); // Data has been loaded
      }
    }
  };

  const handleFieldChange = debounce((e) => {
    const { name, value } = e.target;
    const parsedValue = parseFloat(value) || 0;

    if (name === "latitude") {
      setLatitude(parsedValue);
      map(parsedValue, longitude); // Call map with the new latitude and current longitude
    } else if (name === "longitude") {
      setLongitude(parsedValue);
      map(latitude, parsedValue); // Call map with the current latitude and new longitude
    }
  }, 500);

  const handleParishCheckChange = (e) => {
    const checked = e.target.checked;
    setParishCheck(checked);

    if (!checked) {
      form.setFieldsValue({ parish: undefined });
    }
  };

  return (
    <>
      <div>
        {selectedIpPlan == undefined ||
        selectedIpPlan == null ||
        selectedIpPlan == "" ? (
          <></>
        ) : (
          <button
            style={{
              marginTop: "0",
              marginBottom: "15px",
              border: "none",
              backgroundColor: "transparent",
              color: "var(--2, #AA1A5F)",
              fontWeight: "500",
            }}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              seeIpPlanDetails();
            }}
          >
            {showHideIpPlanDetails
              ? "Hide IP Plan details"
              : "Show IP Plan details"}

            <img src={DownArrow} alt="toggle" />
          </button>
        )}
        {showHideIpPlanDetails && (
          <div className="teamDetails mb-5">
            <Card title="Ip Plan General Details">
              <Divider />
              <div className="d-flex flex-row flex-wrap gap-5">
                <p className="d-flex flex-column">
                  <span style={{ fontWeight: "700" }}>
                    Implementing Partner:
                  </span>{" "}
                  {selectedIpPlan?.implementingPartner?.name}
                </p>
                <p className="d-flex flex-column">
                  <span style={{ fontWeight: "700" }}>Governorate/State:</span>{" "}
                  {selectedIpPlan?.ipPlanStates
                    ?.map((state) => state?.stateName)
                    .join(", ")}
                </p>
                {selectedIpPlan?.ipPlanCities?.length > 0 && (
                  <p className="d-flex flex-column">
                    <span style={{ fontWeight: "700" }}>
                      City/Disctrict/Municipality:
                    </span>{" "}
                    {selectedIpPlan?.ipPlanCities
                      ?.map((city) => city?.name)
                      .join(", ")}
                  </p>
                )}
                <p className="d-flex flex-column">
                  <span style={{ fontWeight: "700" }}>
                    Assigned to a field coordinator:
                  </span>{" "}
                  {selectedIpPlan?.assignee?.userDetails?.firstName
                    ? `${selectedIpPlan?.assignee?.userDetails?.firstName}
                     ${selectedIpPlan?.assignee?.userDetails?.lastName}`
                    : selectedIpPlan?.assignee?.email}
                </p>
              </div>
            </Card>
          </div>
        )}
        <Row gutter={16}>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Latitude (x°)"
                  name="latitude"
                  initialValue={isEditing ? latitude : 0} // Set default value for adding
                  rules={[
                    { required: true, message: "Please enter latitude" },
                    {
                      validator: (_, value) => {
                        const numValue = parseFloat(value);
                        if (numValue > 90 || numValue < -90) {
                          return Promise.reject(
                            "Latitude must be between -90 and 90"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    type="number"
                    min={-90}
                    max={90}
                    name="latitude"
                    placeholder="Enter latitude value"
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Longitude (y°)"
                  name="longitude"
                  initialValue={isEditing ? longitude : 0}
                  rules={[
                    { required: true, message: "Please enter longitude" },
                    {
                      validator: (_, value) => {
                        const numValue = parseFloat(value);
                        if (numValue > 180 || numValue < -180) {
                          return Promise.reject(
                            "Longitude must be between -180 and 180"
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input
                    min={-180}
                    max={180}
                    type="number"
                    name="longitude"
                    placeholder="Enter longitude value"
                    onChange={handleFieldChange}
                  />
                </Form.Item>
              </Col>
              <div className="w-100 d-flex flex-column gap-1 mb-4">
                <Form.Item name="parishCheck" className="m-0 p-0">
                  <Checkbox
                    value={parishCheck}
                    checked={parishCheck}
                    onChange={handleParishCheckChange}
                    id="parish"
                  >
                    <span className="parish">Parish </span>
                    <span
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "gray",
                      }}
                    >
                      (optional)
                    </span>
                  </Checkbox>
                </Form.Item>
                {parishCheck && (
                  <Form.Item name="parish" className="m-0 p-2">
                    <Input type="text" placeholder="Please enter parish" />
                  </Form.Item>
                )}
              </div>
              <Col span={24}>
                <Form.Item label="Selected Address" name="address">
                  <p
                    style={{ fontSize: "14px", lineHeight: "1.8" }}
                    className="border rounded p-3 mb-3 bg-light"
                  >
                    {address?.parish ? address?.parish : "Address not found"}
                  </p>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={16}>
              <Col span={20}>
                <Form.Item label="Search" name="search">
                  <div
                    style={{ position: "relative", zIndex: 2 }}
                    className="osm-search"
                  >
                    <OpenStreetMapAutocomplete
                      value={null}
                      onChange={(val) => selectLocation(val)}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  <Tooltip title="This feature works only when you have location enabled in your browser!">
                    <Button
                      onClick={handleGetCurrentLocation}
                      style={{ marginTop: 30 }}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <AimOutlined style={{ color: "#999" }} />
                    </Button>
                  </Tooltip>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Spin spinning={mapLoading}>
                  <div
                    style={{
                      height: "400px",
                      width: "100%",
                      position: "relative",
                      zIndex: 0,
                    }}
                  >
                    <MapContainer
                      center={position} // Use current position state
                      zoom={zoomLevel} // Use dynamic zoom level
                      style={{ height: "100%", width: "100%" }}
                      maxBounds={[
                        [-90, -180],
                        [90, 180],
                      ]}
                      maxBoundsViscosity={1.0}
                    >
                      <MapWithInvalidateSize>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        {!loading && position && (
                          <LocationMarker
                            position={position}
                            setPosition={setPosition}
                            form={form}
                          />
                        )}
                        <MapCenterUpdater position={position} />
                      </MapWithInvalidateSize>
                    </MapContainer>
                  </div>
                </Spin>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            span={12}
            style={{
              marginTop: "-110px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Form.Item
                name="isVirtual"
                style={{ marginBottom: "0" }}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <span>This visit will be virtual</span>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LocationDetails;
