import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Table, Button, message } from "antd";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import download from "../../../Assets/svg/downloadList.svg";
import { httpService } from "../../../services/httpService.service";
import ExportModal from "./exportModal";
import { extractName } from "../../../services/helpFunctions/extractNameOfFile";


const ExportTable = ({ visible, onCancel }) => {
  const [data, setData] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const projectId = localStorage.getItem("currentProjectID");
  const [isExportModalVisible, setExportModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showExportModal = () => {
    setExportModalVisible(true);
  };

  const closeExportModal = () => {
    checkJobStatus();
    setExportModalVisible(false);
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    const hours = d.getHours().toString().padStart(2, "0");
    const minutes = d.getMinutes().toString().padStart(2, "0");
    const seconds = d.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const fetchExportFiles = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        "https://kapfilemanager.kapsurvey.com/FileData/getExportFileByPlatform",
        {
          params: {
            entityId: projectId,
            folderName: "TPMSiteVisits",
          },
        }
      );
      setData(response.data);
    } catch (error) {
      message.error("Failed to fetch files. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchExportFiles();
      checkJobStatus()
    }
  }, [visible]);

  const pollEndpoint = async (url, useHttpService = false) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        try {
          let response;
          if (useHttpService) {
            response = await new Promise((res, rej) =>
              httpService.get(
                url,
                (data) => res(data),
                (error) => rej(error)
              )
            );
          } else {
            const axiosResponse = await axios.get(url);
            response = axiosResponse.data;
          }

          const isProcessingJobs = response.data.some(
            (job) =>
              job.stateName === "Processing" || job.stateName === "Scheduled"
          );

          if (!isProcessingJobs) {
            clearInterval(interval);
            resolve();
            setIsProcessing(false);
          } else {
            setIsProcessing(true);
          }
        } catch (error) {
          clearInterval(interval);
          reject(error);
        }
      }, 7000);
    });
  };

  const checkJobStatus = async () => {
    try {
      await pollEndpoint(
        `api/Hangfire/getProjectJobs?projectId=${projectId}`,
        true
      );
      await pollEndpoint(
        `https://kapfilemanager.kapsurvey.com/FileData/getEntityJobs?entityId=${projectId}&exportReason="ProjectSiteVisitsExport"`
      );

      fetchExportFiles();
    } catch (error) {
      // message.error("Error while checking job status. Please try again.");
    }
  };

  const columns = [
    {
      title: "File Name",
      dataIndex: "fileKey",
      key: "fileKey",
      render: (text) => {
        const fileName = text?.split("/").pop();
        return extractName(fileName);
      },
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      render: (date) => (date ? formatDate(date) : "Processing..."),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src={download}
            alt="Download"
            style={{
              cursor:
                record.url && record.fileKey !== "Processing..."
                  ? "pointer"
                  : "not-allowed",
              opacity:
                isProcessing && record.fileKey === "Processing..." ? 0.5 : 1,
            }}
            onClick={() => {
              if (record.url && record.fileKey !== "Processing...") {
                window.open(record.url, "_blank");
              }
            }}
          />
        </div>
      ),
    },
  ];

  const dataSource = isProcessing
    ? [
        ...data,
        {
          fileKey: "Processing...",
          createdDate: null,
          url: null,
        },
      ]
    : data;

  return (
    <Modal
      visible={visible}
      closable={false}
      footer={false}
      width={900}
      onCancel={onCancel}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={onCancel}
        />
      </div>
      <h2 className="modalHeader">Export Selected Cards</h2>
      <Table
        rowKey="fileKey"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        loading={isLoading}
      />
      <div className="buttonsModal" style={{ marginTop: "32px" }}>
        <Button className="inviteButtonTPM" key="cancel" onClick={onCancel}>
          Back
        </Button>
        <Button className="addButtonTPM" onClick={showExportModal}>
          Export
        </Button>
      </div>
      <ExportModal visible={isExportModalVisible} onCancel={closeExportModal} />
    </Modal>
  );
};

export default ExportTable;
