import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Preview from "../../pages/planning/VisitBoard/TableView/modals/Preview";
import { httpService } from "../../../services/httpService.service";

dayjs.extend(isBetween);

const CalendarViewWeekMode = ({
  selectedWeek,
  selectedMonth,
  selectedYear,
}) => {
  const projectID = localStorage.getItem("currentProjectID");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [siteVisits, setSiteVisits] = useState([]);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState("");
  const startOfMonth = dayjs(selectedMonth).startOf("month");
  const startOfWeek = startOfMonth.add((selectedWeek - 1) * 7, "day");
  const endOfWeek = startOfWeek.add(6, "day");

  const startDate = startOfWeek.format("ddd, DD MMM YYYY HH:mm:ss [GMT]");
  const endDate = endOfWeek.format("ddd, DD MMM YYYY HH:mm:ss [GMT]");

  useEffect(() => {
    const params = {
      ProjectId: projectID,
      startDate,
      endDate,
    };

    const queryString = new URLSearchParams(params).toString();

    httpService.get(`/api/Site/trainingAnalysis?${queryString}`, (res) => {
      const data = res?.data;

      const groupedData = data?.reduce((acc, siteVisit) => {
        const state = siteVisit.stateName || "Unknown";
        if (!acc[state]) acc[state] = [];

        siteVisit.siteVisitTraining.forEach((training) => {
          const siteVisitEntry = {
            key: training.siteVisitId,
            name: training.siteVisitName,
            estimatedDayOfVisit: training.visitPlanned || "",
            trainingDates: training.trainingDates || [],
          };

          acc[state].push(siteVisitEntry);
        });

        return acc;
      }, {});

      const structuredData = Object.entries(groupedData || {}).map(
        ([stateName, visits]) => {
          const visitsWithDates = visits.flatMap((visit) => {
            const visitEntries = [];

            if (visit.estimatedDayOfVisit) {
              visitEntries.push({
                siteVisitId: visit.key,
                siteVisitName: visit.name,
                date: visit.estimatedDayOfVisit,
                type: "visit",
                color: "#A7587F",
              });
            }

            visit.trainingDates.forEach((trainingDate) => {
              visitEntries.push({
                siteVisitId: visit.key,
                siteVisitName: visit.name,
                date: trainingDate,
                type: "training",
                color: "#B9A5AF",
              });
            });

            const uniqueDates = [
              ...new Set([visit.estimatedDayOfVisit, ...visit.trainingDates]),
            ];
            const finalEntries = uniqueDates.map((date) => {
              const isVisit = visit.estimatedDayOfVisit === date;
              const isTraining = visit.trainingDates.includes(date);

              return {
                siteVisitId: visit.key,
                siteVisitName: visit.name,
                date,
                type:
                  isVisit && isTraining
                    ? "both"
                    : isVisit
                    ? "visit"
                    : "training",
                color:
                  isVisit && isTraining
                    ? "#EF7DB5"
                    : isVisit
                    ? "#A7587F"
                    : "#B9A5AF",
              };
            });

            return finalEntries;
          });

          // Count unique site visits and unique days with visits
          const uniqueSiteVisits = new Set(visits.map((v) => v.name)).size;
          const uniqueVisitDays = new Set(visitsWithDates.map((v) => v.date))
            .size;

          return {
            state: stateName,
            visits: visitsWithDates,
            countOfVisits:
              uniqueSiteVisits > 1
                ? `${uniqueSiteVisits} visits`
                : `${uniqueSiteVisits} visit`,
            totalDays:
              uniqueVisitDays > 1
                ? `${uniqueVisitDays} days`
                : `${uniqueVisitDays} day`,
          };
        }
      );

      const filteredData = structuredData
        .flatMap((record) => {
          const flattenedVisits = record.visits.filter((visit) =>
            dayjs(visit.date).isBetween(startOfWeek, endOfWeek, "day", "[]")
          );

          return flattenedVisits.length
            ? {
                state: record.state,
                visits: flattenedVisits,
                countOfVisits: record.countOfVisits,
                totalDays: record.totalDays,
              }
            : null;
        })
        .filter(Boolean);

      console.log(filteredData);

      setSiteVisits(filteredData);
    });
  }, [startDate, endDate]);

  const generateColumns = () => [
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      fixed: "left",
      width: siteVisits?.length ? 200 : 100,
    },
    {
      title: "# of visits",
      dataIndex: "countOfVisits",
      key: "countOfVisits",
      fixed: "left",
      width: 100,
      align: "center",
    },
    {
      title: "Total days",
      dataIndex: "totalDays",
      key: "totalDays",
      fixed: "left",
      width: 100,
      align: "center",
      className: "cellsRightShadow",
    },
    ...Array.from({ length: 7 }, (_, i) => {
      const currentDay = startOfWeek.add(i, "day");

      return {
        title: `${currentDay.format("ddd")} ${currentDay.format("DD")}`,
        key: `day${i + 1}`,
        align: "center",
        className: "dataCell",
        render: (_, record) => renderCell(currentDay, record),
      };
    }),
  ];

  const renderCell = (currentDay, record) => {
    const visitsOfCurrentDay = [];
    const trainingsOfCurrentDay = [];

    // Separate visits and trainings for current day
    record.visits.forEach((visit) => {
      if (dayjs(visit.date).isSame(currentDay, "day")) {
        if (visit.type === "visit") {
          visitsOfCurrentDay.push(visit);
        } else if (visit.type === "training") {
          trainingsOfCurrentDay.push(visit);
        }
      }
    });

    const backgroundColor =
      visitsOfCurrentDay.length && trainingsOfCurrentDay.length
        ? "#EF7DB5"
        : visitsOfCurrentDay.length
        ? "#A7587F"
        : trainingsOfCurrentDay.length
        ? "#B9A5AF"
        : "transparent";

    const boxShadow =
      visitsOfCurrentDay.length || trainingsOfCurrentDay.length
        ? "1px 0px 5px rgba(0, 0, 0, 0.3)"
        : "none";

    return {
      props: {
        style: { backgroundColor, boxShadow },
      },
      children: (
        <Tooltip
          title={
            visitsOfCurrentDay.length || trainingsOfCurrentDay.length
              ? `Visits: ${visitsOfCurrentDay.length}, Trainings: ${trainingsOfCurrentDay.length}`
              : "No site visits or training on this date"
          }
        >
          <div className="cellWithData">
            {visitsOfCurrentDay.length > 0 && (
              <>
                <p
                  className="counter m-0 mb-1"
                  style={{ color: backgroundColor }}
                >
                  {visitsOfCurrentDay.length} Visits
                </p>
                {visitsOfCurrentDay.map((visit) => (
                  <Tooltip key={visit.siteVisitId} title={visit.siteVisitName}>
                    <a
                      type="button"
                      onClick={() => handleSiteVisitClick(visit.siteVisitId)}
                      className="w-100 text-truncate m-0 p-0"
                    >
                      {visit.siteVisitName}
                    </a>
                  </Tooltip>
                ))}
              </>
            )}
            {trainingsOfCurrentDay.length > 0 && (
              <>
                <p
                  className="counter m-0 mb-1"
                  style={{ color: backgroundColor }}
                >
                  {trainingsOfCurrentDay.length} Trainings
                </p>
                {trainingsOfCurrentDay.map((visit) => (
                  <Tooltip key={visit.siteVisitId} title={visit.siteVisitName}>
                    <a
                      type="button"
                      onClick={() => handleSiteVisitClick(visit.siteVisitId)}
                      className="w-100 text-truncate m-0 p-0"
                    >
                      {visit.siteVisitName}
                    </a>
                  </Tooltip>
                ))}
              </>
            )}
          </div>
        </Tooltip>
      ),
    };
  };

  // Handle site visit click
  const handleSiteVisitClick = (id) => {
    setSelectedSiteVisit(id);
    setShowPreviewModal(true);
  };

  return (
    <>
      <Preview
        visible={showPreviewModal}
        onClose={() => setShowPreviewModal(false)}
        siteVisitCardId={selectedSiteVisit}
      />
      <Table
        rowKey="state"
        columns={generateColumns()}
        dataSource={siteVisits}
        bordered
        pagination={false}
        scroll={{ x: "max-content", y: 500 }}
        tableLayout="fixed"
        className="w-100"
      />
    </>
  );
};

export default CalendarViewWeekMode;
