import React, { useState, useEffect } from "react";
import "../../../pages/reporting/Reporting.scss";
import { Button } from "antd";
import ExportButton from "../../../../Assets/svg/exportButton.svg";
import TableView from "./TableView/TableView";
import { httpService } from "../../../../services/httpService.service";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import ExportTable from "../../../components/ExportExcel/exportTable";
import ExportModal from "../../../components/ExportExcel/exportModal";
import ExportSelectedTable from "../../../components/ExportExcel/ExportSelectedExcel/exportSelectedTable";

function SiteVisitSummary() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, });
  const projectId = localStorage.getItem("currentProjectID");
  const [selectedSiteVisitIds, setSelectedSiteVisitIds] = useState([]);
  const [selectedSiteVisits, setSelectedSiteVisits] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSelectedModalVisible, setIsSelectedModalVisible] = useState(false);

  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const [filters, setFilters] = useState({
    ImplementingPartnerId: [],
    State: [],
    SiteVisitStatus: [],
    FieldTeamReportingStatus: "",
    ClusterId: [],
    Month: [],
    Year: [],
    Search: "",
    Governates: [],
    Cities: [],
  });

  const fetchSiteVisits = async (ignorePagination) => {
    const params = {
      ProjectId: projectId,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      ImplementingPartnerId: filters.ImplementingPartnerId,
      State: filters.State,
      SiteVisitStatus: filters.SiteVisitStatus?.length
        ? filters.SiteVisitStatus
        : [2, 3, 4, 5, 6],
      FieldTeamReportingStatus: filters.FieldTeamReportingStatus,
      ClusterId: filters.ClusterId,
      Month: filters.Month,
      Year: filters.Year,
      Search: filters.Search,
      Governates: filters.Governates,
      Cities: filters.Cities,
      IgnorePagination: ignorePagination,
    };

    const expandArrayParams = (params) => {
      const expanded = [];
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => expanded.push([key, v]));
        } else if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "number" && isNaN(value))
        ) {
          expanded.push([key, value]);
        }
      }
      return expanded;
    };

    const queryString = new URLSearchParams(
      expandArrayParams(params)
    ).toString();

    setLoading(true);
    await httpService.get(
      `/api/Site/getAllSiteVisits?${queryString}`,
      (response) => {
        setData(response.data);
        setPagination({
          ...pagination,
          total: response.metaData.totalItemCount,
        });
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching site visits:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchSiteVisits();
  }, [filters, pagination.pageSize, pagination.current]);

  const handleFilterChange = (key, value) => {
    setFilters({
      ...filters,
      [key]: value,
    });
    setPagination({ ...pagination, current: 1 });
  };

  const onPageChange = (pageNumber, pageSize) => {
    setPagination({ ...pagination, current: pageNumber, pageSize });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedSiteVisits([]);
  };

  const handleSelectedModalCancel = () => {
    setIsSelectedModalVisible(false);
  };

  const handleExportClick = () => {
    setIsModalVisible(true);
  };

  const handleSelectedExportClick = () => {
    setIsSelectedModalVisible(true);
  };

  return (
    <div
      className="tableViewcontainer visitBoardComponent"
      style={{ padding: "60px 40px 80px 40px" }}
    >
      <div className="tableHeader">
        <span className="tableViewHeaderText">Site Visit Summary</span>

        <MessagePopUp
          trigger={message.trigger}
          type={message.type}
          icon={message.icon}
          messageTitle={message.title}
          messageText={message.text}
        />
        <div className="tableViewHeaderButtons">
          <Button className="addButtonTPM" onClick={handleSelectedExportClick} >
            <img src={ExportButton} alt="" />
            Export Selected Site Visits
          </Button>

          <Button
            className="exportButtonTPM addButtonTPM"
            onClick={handleExportClick}
          >
            <img src={ExportButton} alt="" />
            Export All Site Visits in this Project
          </Button>
        </div>
      </div>
      <TableView
        data={data}
        handleFilterChange={handleFilterChange}
        loading={loading}
        filters={filters}
        setFilters={setFilters}
        onPageChange={onPageChange}
        pagination={pagination}
        setPagination={setPagination}
        onRowSelectionChange={setSelectedSiteVisitIds}
      />
      <ExportTable visible={isModalVisible} onCancel={handleModalCancel} />
      <ExportSelectedTable
        visible={isSelectedModalVisible}
        onCancel={handleSelectedModalCancel}
        selectedSiteVisitIds={selectedSiteVisitIds}
      />
    </div>
  );
}

export default SiteVisitSummary;
