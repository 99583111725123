import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { httpService } from "../../../../services/httpService.service";
import dayjs from "dayjs";

const Option = Select;

const months = {
  january: 0,
  february: 1,
  march: 2,
  april: 3,
  may: 4,
  june: 5,
  july: 6,
  august: 7,
  september: 8,
  october: 9,
  november: 10,
  december: 11,
};

const years = [];
for (let year = 2024; year <= 2030; year++) {
  years.push(
    <>
      <Option key={year} value={year}>
        {dayjs().year() == year ? `Current Year (${year})` : year}
      </Option>
    </>
  );
}

const currentMonthIndex = dayjs().month();
const currentMonthName = Object.keys(months).find(
  (month) => months[month] === currentMonthIndex
);
const monthOptions = Object.entries(months).map(([key, value]) => (
  <Option key={value} value={value}>
    {value === currentMonthIndex
      ? `Current Month (${
          currentMonthName.charAt(0).toUpperCase() + currentMonthName.slice(1)
        })`
      : key.charAt(0).toUpperCase() + key.slice(1)}
  </Option>
));

const Filters = ({ filters, setFilters, implementingPartner = false }) => {
  const [form] = Form.useForm();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [implementingPartners, setImplementingPartners] = useState([]);

  useEffect(() => {
    fetchCountries();
    const contractId = localStorage.getItem("currentContractId");
    fetchImplementingPartners(contractId);
  }, []);

  const fetchImplementingPartners = (contractId) => {
    httpService.get(
      `/api/ImplementingPartner/getAllImplementingPartners?ContractId=${contractId}`,
      (res) => {
        setImplementingPartners(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchCountries = () => {
    httpService.get(
      `/api/GeoData/countries`,
      (res) => {
        setCountries(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchStates = (countryIds) => {
    httpService.get(
      `/api/GeoData/states?countryIds=${countryIds}`,
      (res) => {
        setStates(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleCountryChange = (country) => {
    form.setFieldsValue({ state: 1 });
    const countryName = country.children;
    const countryId = country.value;
    if (countryId == 1) {
      setStates([]);
      setFilters({ ...filters, state: "" });
      return;
    }
    fetchStates(countryId);
    setFilters({ ...filters, state: countryName });
  };

  const handleStateChange = (state) => {
    if (state === 1) {
      setFilters({ ...filters, state: "" });
      return;
    }
    setFilters({ ...filters, state: state });
  };

  const handleMonthChange = (month) => {
    const year = form.getFieldValue("year") || dayjs().year();

    setFilters({
      ...filters,
      startDate: dayjs()
        .year(Number(year))
        .month(Number(month) || 0)
        .startOf("month"),
      endDate: dayjs()
        .year(Number(year))
        .month(Number(month) || 0)
        .endOf("month"),
    });
  };

  const handleYearChange = (year) => {
    const month = form.getFieldValue("month") || 0;

    setFilters({
      ...filters,
      startDate: dayjs()
        .year(Number(year))
        .month(Number(month))
        .startOf("month"),
      endDate: dayjs().year(Number(year)).month(Number(month)).endOf("month"),
    });
  };
  return (
    <Form form={form} className="tpmSummaryHeader flex-wrap">
      {implementingPartner && (
        <Form.Item name="implementingPartner">
          <Select
            value={1}
            style={{ width: 220 }}
            onChange={(implementingPartnerId) =>
              setFilters({
                ...filters,
                implementingPartnerId:
                  implementingPartnerId !== 1 ? implementingPartnerId : "",
              })
            }
            defaultValue={1}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().startsWith(input.toLowerCase())
            }
          >
            <Option key={1} value={1}>
              All Implementing Partners
            </Option>
            {implementingPartners.map((partner) => (
              <Option key={partner.id} value={partner.id}>
                {partner.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item name="country">
        <Select
          value={1}
          style={{ width: 200 }}
          onChange={(countryName, country) => handleCountryChange(country)}
          defaultValue={1}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          <Option key={1} value={1}>
            All Countries
          </Option>

          {countries.map((country) => (
            <Option key={country.id} value={country.id}>
              {country.stateName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      {form.getFieldValue("country") != 1 &&
        form.getFieldValue("country") != undefined && (
          <Form.Item name="state">
            <Select
              value={1}
              style={{ width: 200 }}
              onChange={handleStateChange}
              defaultValue={1}
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().startsWith(input.toLowerCase())
              }
            >
              <Option key={1} value={1}>
                All States
              </Option>

              {states.map((state) => (
                <Option key={state.stateName} value={state.stateName}>
                  {state.stateName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      <Form.Item name="year">
        <Select
          value={1}
          style={{ width: 200 }}
          defaultValue={dayjs().year()}
          onChange={handleYearChange}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {years}
        </Select>
      </Form.Item>
      <Form.Item name="month">
        <Select
          value={1}
          style={{ width: 220 }}
          onChange={handleMonthChange}
          defaultValue={dayjs().month()}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().startsWith(input.toLowerCase())
          }
        >
          {monthOptions}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default Filters;
