import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import dayjs from "dayjs";
import "./VisitDataSummary.scss";
import Filters from "./Filters";
import { httpService } from "../../../../services/httpService.service";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Accessibility = () => {
  const [distributionData, setDistributionData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [filters, setFilters] = useState({
    state: "",
    startDate: "",
    endDate: "",
  });

  const fetchData = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      StartDate: filters.startDate || dayjs().startOf("month"),
      EndDate: filters.endDate || dayjs().endOf("month"),
      Country: filters.state,
    };

    const queryString = new URLSearchParams(params).toString();

    httpService.get(
      `/api/Site/accessibilityInsights?${queryString}`,
      (res) => {
        setDistributionData(res?.data?.accessibilityDistribution);
        setCategoriesData(res?.data?.accesibilityCategoriesResponse);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const hasAccessibilityData =
    Array.isArray(distributionData) && distributionData.length > 0;

  const labels = hasAccessibilityData
    ? distributionData.map((item) => item.locationState)
    : [];

  const categories = hasAccessibilityData
    ? Object.keys(distributionData[0]).filter((key) => key !== "locationState")
    : [];

  const staticColors = {
    challenging: "#F09E70",
    critical: "#E08585",
    semiAccessible: "#FFD88C",
    accessible: "#B6CE84",
  };

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const accessibilityDistributionData = {
    labels,
    datasets: categories.map((category) => {
      const color = staticColors[category] || generateRandomColor();
      return {
        label: category.charAt(0).toUpperCase() + category.slice(1),
        data: hasAccessibilityData
          ? distributionData.map((item) => item[category] || 0)
          : Array(labels.length).fill(100),
        backgroundColor: hasAccessibilityData ? color : "rgba(0, 0, 0, 0.1)",
        borderColor: hasAccessibilityData ? color : "rgba(0, 0, 0, 0.1)",
        borderWidth: 1,
      };
    }),
  };

  const accessibilityDistributionOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: hasAccessibilityData
          ? "Accessibility Distribution"
          : "No Data Available",
        font: {
          size: 18,
          weight: "600",
          style: "normal",
          family: "Poppins",
          lineHeight: "26px",
        },
        color: "#6A113C",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return hasAccessibilityData
              ? `${tooltipItem.dataset.label}: ${tooltipItem.raw}`
              : "No Data Available";
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
      },
      y: {
        stacked: true,
      },
    },
  };

  const hasData = categoriesData.some(
    (item) => item?.postponed > 0 || item?.completed > 0
  );

  const datasetKeys = ["postponed", "completed"];
  const colors = {
    postponed: "#E08585",
    completed: "#B6CE84",
  };

  const statusOfVisitsData = {
    labels: categoriesData.map((item) => item?.optionName),
    datasets: hasData
      ? datasetKeys.map((key) => ({
          label: `Visits ${key.charAt(0).toUpperCase() + key.slice(1)}`,
          data: categoriesData.map((item) => item?.[key] || 0),
          backgroundColor: colors[key],
          borderColor: colors[key],
          borderWidth: 1,
        }))
      : [
          {
            label: "No data",
            data: Array(categoriesData.length).fill(100),
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            borderColor: "rgba(0, 0, 0, 0.1)",
            borderWidth: 1,
          },
        ],
  };

  const statusOfVisitsOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Status of Visits Based on Accessibility Categories",
        font: {
          size: 18,
          weight: "600",
          style: "normal",
          family: "Poppins",
          lineHeight: "26px",
        },
        color: "#6A113C",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (!hasData) return "No Data Available";
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        min: 0,
        max: 100,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />
      <div className="chart-card" style={{ width: "100%" }}>
        <Bar
          data={accessibilityDistributionData}
          options={accessibilityDistributionOptions}
        />
      </div>
      <div className="chart-card" style={{ width: "100%", height: "400px" }}>
        <Bar data={statusOfVisitsData} options={statusOfVisitsOptions} />
      </div>
    </>
  );
};

export default Accessibility;
