import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import Map from "./Map";
import Filters from "./Filters";
import dayjs from "dayjs";
import { httpService } from "../../../../services/httpService.service";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const GeographicsTrends = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [mapData, setMapData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [filters, setFilters] = useState({
    state: "",
    startDate: "",
    endDate: "",
  });

  const fetchData = () => {
    setLoadingData(true);
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      StartDate: filters.startDate || dayjs().startOf("month"),
      EndDate: filters.endDate || dayjs().endOf("month"),
      Country: filters.state,
    };

    const queryString = new URLSearchParams(params).toString();

    httpService.get(
      `/api/Site/geographicTrends?${queryString}`,
      (res) => {
        setMapData(res?.data?.siteVisitLongitudeLatitudeStatuses);
        setChartData(res?.data?.siteVisitStateStatusResponses);
        setLoadingData(false);
      },
      (err) => {
        console.log(err);
        setLoadingData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const staticColors = {
    notInitiated: "#999",
    inProgress: "#FFD88C",
    completed: "#B6CE84",
    postponed: "#E08585",
  };

  const generateColor = (key) => {
    const hash = key
      .split("")
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return `hsl(${hash % 360}, 70%, 60%)`;
  };

  const allStatusKeys = Array.from(
    new Set(
      chartData.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "stateName")
      )
    )
  );

  const datasets = allStatusKeys.map((status) => ({
    label: status.replace(/([A-Z])/g, " $1").trim(),
    data: chartData.map((item) => item[status] || 0),
    backgroundColor: staticColors[status] || generateColor(status),
    borderColor: staticColors[status] || generateColor(status),
    borderWidth: 1,
  }));

  const accessibilityDistributionData = {
    labels: chartData.map((item) => item.stateName),
    datasets,
  };

  const accessibilityDistributionOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Count of Visits by State",
        font: {
          size: 18,
          weight: "600",
          style: "normal",
          family: "Poppins",
          lineHeight: "26px",
        },
        color: "#6A113C",
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />

      <div>
        <Map data={mapData} loadingData={loadingData} />
      </div>
      <div className="chart-card" style={{ width: "100%", height: "400px" }}>
        <Bar
          data={accessibilityDistributionData}
          options={accessibilityDistributionOptions}
        />
      </div>
    </>
  );
};

export default GeographicsTrends;
