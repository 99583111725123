import React, { useState, useEffect } from "react";
import { Layout, Menu, Dropdown, Button, Spin } from "antd";
import {
  Outlet,
  Link,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./MainLayout.css"; // Import the CSS file
import AdminHeader from "./components/AdminHeader/AdminHeader";
import { MenuOutlined } from "@ant-design/icons";
import logo from "../Assets/svg/TPMlogo.svg";
import icon1 from "../Assets/svg/iconMenu1.svg";
import icon2 from "../Assets/svg/iconMenu2.svg";
import icon3 from "../Assets/svg/iconMenu3.svg";
import icon4 from "../Assets/svg/iconMenu4.svg";
import icon5 from "../Assets/svg/iconMenu5.svg";
import icon6 from "../Assets/svg/iconMenu6.svg";
import icon7 from "../Assets/svg/iconMenu7.svg";
import backToWorkspace from "../Assets/svg/backToWorkspace.svg";
import menu3 from "../Assets/svg/menu3.svg";
import { RolesEnum } from "../services/rolesEnum";
import { isAuthorized } from "../services/authService";
import AuthService from "../services/authentication.service";

const { Header, Content, Sider } = Layout;

const ProjectLayout = ({
  notifications,
  setNotifications,
  unreadNotifications,
  setUnreadNotifications,
}) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState([]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const { pathname, search } = location;
    if (pathname === "/project/planning" && search) {
      setOpenKeys(["2"]);
    } else if (pathname === "/project/implementation" && search) {
      setOpenKeys(["3"]);
    } else if (pathname === "/project/reporting" && search) {
      setOpenKeys(["4"]);
    } else if (pathname === "/project/completed" && search) {
      setOpenKeys(["5"]);
    } else if (pathname === "/project/flags" && search) {
      setOpenKeys(["6"]);
    } else {
      setOpenKeys([]);
    }
  }, [location]);

  const handleOpenChange = (keys) => {
    setOpenKeys(keys.length ? [keys[keys.length - 1]] : []);
  };

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  // Event listener for window resize
  window.addEventListener("resize", handleResize);

  if (window.innerWidth < 768 && !isSmallScreen) {
    setIsSmallScreen(true);
  }

  const handleLogout = async (event) => {
    AuthService.logout();
  };

  const menu = (
    <Menu theme="dark" mode="vertical">
      {isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
        RolesEnum.FieldTeamLeader,
        RolesEnum.Guest,
      ]) && (
        <Menu.Item key="1">
          <Link
            to="/project/dashboard"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Round Dashboard
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="7">
        <Link
          to="/project/ip-management"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Ip management
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link
          to="/project/planning"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Planning
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link
          to="/project/implementation"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Implementation
        </Link>
      </Menu.Item>
      <Menu.Item key="4">
        <Link
          to="/project/reporting"
          style={{ textDecoration: "none", color: "#6A113C" }}
        >
          Reporting
        </Link>
      </Menu.Item>
      {isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
        RolesEnum.Guest,
        RolesEnum.LimitedGuest,
      ]) && (
        <Menu.Item key="5">
          <Link
            to="/project/completed"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Completed
          </Link>
        </Menu.Item>
      )}
      {isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
        RolesEnum.FieldTeamLeader,
        RolesEnum.FieldEnumerator,
        RolesEnum.FieldLogistics,
      ]) && (
        <Menu.Item key="6">
          <Link
            to="/project/flags"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Flags
          </Link>
        </Menu.Item>
      )}

      <Menu.Item key="8">
        {location.pathname === "/projects" ? (
          <Link
            to="/dashboard/contracts"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Back to contracts
          </Link>
        ) : (
          <Link
            to="/projects"
            style={{ textDecoration: "none", color: "#6A113C" }}
          >
            Back to project rounds
          </Link>
        )}
      </Menu.Item>
      {/* Add more menu items as needed */}
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {isSmallScreen ? null : (
        <Sider className="sider">
          <div className="menuDesign">
            <img src={logo} style={{ marginBottom: "46px" }} alt="logo" />
          </div>

          <Menu
            theme="dark"
            mode="inline"
            openKeys={openKeys}
            onOpenChange={handleOpenChange}
            selectedKeys={[location.pathname + location.search]}
            className="menu"
          >
            {" "}
            <Menu.Item key="8">
              {location.pathname === "/projects" ? (
                <Link
                  to="/dashboard/contracts"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <img
                    src={backToWorkspace}
                    style={{ marginRight: "12px" }}
                    alt="menu2"
                  />
                  Back to contracts
                </Link>
              ) : (
                <Link
                  to="/projects"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    display: "block",
                    textOverflow: "clip",
                  }}
                >
                  <img
                    src={backToWorkspace}
                    style={{
                      marginRight: "12px",
                    }}
                    alt="menu2"
                  />
                  Back to project rounds
                </Link>
              )}
            </Menu.Item>{" "}
            <Menu.Item
              key="7"
              className={
                location.pathname === "/project/ip-management"
                  ? "costum-menu-item-active"
                  : ""
              }
            >
              <Link
                to="/project/ip-management"
                style={{ textDecoration: "none", color: "white" }}
              >
                <img src={menu3} style={{ marginRight: "12px" }} alt="menu1" />
                IP Management
              </Link>
            </Menu.Item>{" "}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldTeamLeader,
              RolesEnum.Guest,
            ]) && (
              <Menu.Item
                key="1"
                className={
                  location.pathname === "/project/dashboard"
                    ? "costum-menu-item-active"
                    : ""
                }
                style={{ marginBottom: "20px" }}
              >
                <Link
                  to="/project/dashboard"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <img
                    src={icon1}
                    style={{ marginRight: "12px" }}
                    alt="menu1"
                  />
                  Dashboard
                </Link>
              </Menu.Item>
            )}
            <p className="siderText">Project Management</p>
            <Menu.SubMenu
              key="2"
              className={
                location.pathname === "/project/planning" && !location.search
                  ? "costum-menu-item-active"
                  : ""
              }
              title={
                <>
                  <img
                    src={icon2}
                    style={{ marginRight: "12px" }}
                    alt="menu2"
                  />
                  Planning
                </>
              }
            >
              {/* Submenu items */}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.Guest,
              ]) && (
                <Menu.Item
                  key="subitem1"
                  className={
                    location.pathname === "/project/planning" &&
                    location.search === "?ip-planning"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/planning?ip-planning"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    IP Planning Board
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
                RolesEnum.FieldEnumerator,
                RolesEnum.FieldLogistics,
                RolesEnum.Guest,
                RolesEnum.LimitedGuest,
              ]) && (
                <Menu.Item
                  key="subitem2"
                  className={
                    location.pathname === "/project/planning" &&
                    location.search === "?visits"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/planning?visits"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Visits Board
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
                RolesEnum.FieldLogistics,
                RolesEnum.FieldTeamLeader,
                RolesEnum.Guest,
              ]) && (
                <Menu.Item
                  key="subitem3"
                  className={
                    location.pathname === "/project/planning" &&
                    location.search === "?tasks"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/planning?tasks"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Tasks Assignments
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
                RolesEnum.FieldLogistics,
                RolesEnum.FieldTeamLeader,
                RolesEnum.Guest,
                RolesEnum.LimitedGuest,
    
              ]) && (
                <Menu.Item
                  key="subitem4"
                  className={
                    location.pathname === "/project/planning" &&
                    location.search === "?logistics"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/planning?logistics"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Field Logistics Board
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
            <Menu.SubMenu
              key="3"
              className={
                location.pathname === "/project/implementation" &&
                !location.search
                  ? "costum-menu-item-active"
                  : ""
              }
              title={
                <>
                  <img
                    src={icon3}
                    style={{ marginRight: "12px" }}
                    alt="menu2"
                  />
                  Implementation
                </>
              }
            >
              {/* Submenu items */}
              <Menu.Item
                key="implementation1"
                className={
                  location.pathname === "/project/implementation" &&
                  location.search === "?VisitBoard"
                    ? "costum-menu-item-active"
                    : ""
                }
              >
                <Link
                  to="/project/implementation?VisitBoard"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Visits Board
                </Link>
              </Menu.Item>
              <Menu.Item
                key="implementation2"
                className={
                  location.pathname === "/project/implementation" &&
                  location.search === "?MonitoringRoundOverview"
                    ? "costum-menu-item-active"
                    : ""
                }
              >
                <Link
                  to="/project/implementation?MonitoringRoundOverview"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Monitoring Round Overview
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.SubMenu
              key="4"
              className={
                location.pathname === "/project/reporting" && !location.search
                  ? "costum-menu-item-active"
                  : ""
              }
              title={
                <>
                  <img
                    src={icon4}
                    style={{ marginRight: "12px" }}
                    alt="menu2"
                  />
                  Reporting
                </>
              }
            >
              {/* Submenu items */}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
                RolesEnum.FieldTeamLeader,
                RolesEnum.FieldEnumerator,
                RolesEnum.FieldLogistics,
              ]) && (
                <Menu.Item
                  key="reporting1"
                  className={
                    location.pathname === "/project/reporting" &&
                    location.search === "?FieldTeamStatusBoard"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/reporting?FieldTeamStatusBoard"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Field Team Status Board
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
                RolesEnum.FieldTeamLeader,
                RolesEnum.FieldEnumerator,
                RolesEnum.FieldLogistics,
              ]) && (
                <Menu.Item
                  key="reporting2"
                  className={
                    location.pathname === "/project/reporting" &&
                    location.search === "?IpDonorStatusBoard"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/reporting?IpDonorStatusBoard"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    IP Donor Status Board
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
                RolesEnum.FieldCoordinator,
                RolesEnum.FieldTeamLeader,
                RolesEnum.FieldLogistics,
              ]) && (
                <Menu.Item
                  key="reporting3"
                  className={
                    location.pathname === "/project/reporting" &&
                    location.search === "?ReportingList"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/reporting?ReportingList"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Visit Board Reporting List
                  </Link>
                </Menu.Item>
              )}
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
              ]) && (
                <Menu.Item
                  key="reporting4"
                  className={
                    location.pathname === "/project/reporting" &&
                    location.search === "?ApproveSiteVisits"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/reporting?ApproveSiteVisits"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Approve Site Visits
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.Guest,
              RolesEnum.LimitedGuest,
            ]) && (
              <Menu.SubMenu
                key="5"
                className={
                  location.pathname === "/project/completed" && !location.search
                    ? "costum-menu-item-active"
                    : ""
                }
                title={
                  <>
                    <img
                      src={icon5}
                      style={{ marginRight: "12px" }}
                      alt="menu2"
                    />
                    Completed
                  </>
                }
              >
                {/* Submenu items */}{" "}
                <Menu.Item
                  key="completed1"
                  className={
                    location.pathname === "/project/completed" &&
                    location.search === "?ReportingList"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/completed?ReportingList"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Completed Site Visits
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="completed2"
                  className={
                    location.pathname === "/project/completed" &&
                    location.search === "?DatasetList"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/completed?DatasetList"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Dataset List
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="completed3"
                  className={
                    location.pathname === "/project/completed" &&
                    location.search === "?VisitsMap"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/completed?VisitsMap"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Visits Map
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            {isAuthorized([
              RolesEnum.ProjectManager,
              RolesEnum.SystemAdmin,
              RolesEnum.FieldCoordinator,
              RolesEnum.FieldTeamLeader,
              RolesEnum.FieldEnumerator,
              RolesEnum.FieldLogistics,
            ]) && (
              <Menu.SubMenu
                key="6"
                className={
                  location.pathname === "/project/flags" && !location.search
                    ? "costum-menu-item-active"
                    : ""
                }
                title={
                  <>
                    <img
                      src={icon6}
                      style={{ marginRight: "12px" }}
                      alt="menu2"
                    />
                    Flags
                  </>
                }
              >
                {/* Submenu items */}{" "}
                <Menu.Item
                  key="flags1"
                  className={
                    location.pathname === "/project/flags" &&
                    location.search === "?Flags"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/flags?Flags"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Red Flags Board
                  </Link>
                </Menu.Item>
                <Menu.Item
                  key="flags2"
                  className={
                    location.pathname === "/project/flags" &&
                    location.search === "?PostVisits"
                      ? "costum-menu-item-active"
                      : ""
                  }
                >
                  <Link
                    to="/project/flags?PostVisits"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    Post-Visit Card Board
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}
            <Menu.Item
              key="9"
              className={
                location.pathname === "/login"
                  ? "costum-menu-item-active logoutMenu"
                  : "logoutMenu"
              }
              onClick={handleLogout}
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <Spin size="small" style={{ marginRight: 8 }} />
                  Logging out...
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <img
                    src={icon7}
                    style={{ marginRight: 12 }}
                    alt="Logout Icon"
                  />
                  Logout
                </div>
              )}
            </Menu.Item>
            {/* Add more menu items as needed */}
          </Menu>
        </Sider>
      )}
      <Layout className="site-layout">
        <Header
          className="adminHeader"
          style={{ minHeight: "100px", padding: "0px !important" }}
        >
          <div className="leftContent">
            {isSmallScreen ? (
              <Dropdown overlay={menu} trigger={["click"]}>
                <MenuOutlined
                  style={{
                    fontSize: "24px",
                    marginBottom: "16px",
                    color: "#6A113C",
                  }}
                />
              </Dropdown>
            ) : null}
          </div>
          <div className="rightContent" style={{ width: "100%" }}>
            <AdminHeader
              notifications={notifications}
              setNotifications={setNotifications}
              unreadNotifications={unreadNotifications}
              setUnreadNotifications={setUnreadNotifications}
            />
          </div>
        </Header>
        <Content style={{ margin: "0px", backgroundColor: "white" }}>
          <Outlet style={{ width: "100% !important" }} />
        </Content>
      </Layout>
    </Layout>
  );
};

export default ProjectLayout;
