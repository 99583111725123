import React, { useEffect, useState } from "react";
import KanbanBoardComponent from "../../../../components/KanbanBoard/KanbanBoardComponent";
import Filters from "../Filters";
import { httpService } from "../../../../../services/httpService.service";
import { Spin, Pagination } from "antd";
import MessagePopUp from "../../../../components/Messages/MessagePopUp";
import Preview from "../../../planning/VisitBoard/TableView/modals/Preview";
import exclamationMark from "../../../../../Assets/svg/exclamationMark.svg";
import done from "../../../../../Assets/svg/done.svg";
import { formatDate } from "../../../../../services/helpFunctions/formatDateAndTime";
import ConfirmModal from "../../../../components/confirmModal/ConfirmModal";
import AddTrackingCardModal from "../../../planning/VisitBoard/Modals/AddTrackingCardModal";

const KanbanView = ({
  siteVisitCards,
  setSiteVisitCards,
  fetchSiteVisitCards,
  loading,
  setLoading,
  onPageChange,
  pagination,
  setPagination,
  handleFilterChange,
  filters,
  setFilters,
}) => {
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [clickedTaskId, setClickedTaskId] = useState();
  const [showAddTrackingModal, setShowAddTrackingModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });
  const projectID = localStorage.getItem("currentProjectID");

  useEffect(() => {
    fetchSiteVisitCards();
  }, [pagination.current, pagination.pageSize]);

  const handleTaskClick = (taskId) => {
    setClickedTaskId(taskId);
    setShowPreviewModal(!showPreviewModal);
  };

  const handleTogglePreviewModal = () => {
    setShowPreviewModal(!showPreviewModal);
  };

  const handleChangeConfirm = (siteVisitId) => {
    setSelectedSiteVisit(siteVisitId);
    setShowAddTrackingModal(true);
  };

  const changeSiteVisitCardStatus = (draggedTask) => {
    setLoading(true);
    const draggedSiteVisit = siteVisitCards.find(
      (siteVisitCard) => siteVisitCard.id === draggedTask.id
    );
    if (!draggedSiteVisit || draggedSiteVisit.length === 0) {
      console.error("No matching site visit card found");
      return;
    }

    if (!draggedTask?.hasTracking && [4, 5].includes(draggedTask?.column)) {
      ConfirmModal(
        () => handleChangeConfirm(draggedTask.id), // Pass the function as a callback
        "Warning",
        "The selected status requires this site visit to have tracking!", // Information
        "Do you want to add tracking to this site visit?", // Question
        "Yes" // Confirm button text
      );
      setLoading(false);
      return;
    }

    const siteVisitId = draggedSiteVisit.id;
    const siteVisitStatus = Number(draggedTask.column);

    if (!siteVisitId || isNaN(siteVisitStatus)) {
      console.error("Invalid siteVisit id or siteVisitStatus", {
        siteVisitId,
        siteVisitStatus,
      });
      return;
    }

    const updatedSiteVisitCards = siteVisitCards?.map((card) => {
      if (card.id === draggedTask.id) {
        return {
          ...card,
          siteVisitStatus: siteVisitStatus,
        };
      }
      return card;
    });

    setSiteVisitCards(updatedSiteVisitCards);

    httpService.put(
      `/api/Site/changeSiteVisitStatus?SiteVisitId=${siteVisitId}&SiteVisitStatus=${siteVisitStatus}`,
      "",
      (response) => {
        setMessage({
          trigger: true,
          type: "success",
          icon: done,
          title: "Status updated successfully!",
          text: "Site Visit Status was correctly updated to your database.",
        });
        fetchSiteVisitCards(
          projectID,
          filters?.pageSize,
          filters?.current,
          filters.Search,
          filters.ImplementingPartnerId,
          filters.State,
          filters.SiteVisitStatus,
          filters.ClusterId,
          filters.Month,
          filters.Year
        );
        setLoading(false);
      },
      (error) => {
        setSiteVisitCards(siteVisitCards);

        const errorMessage =
          error?.errors?.[0]?.message ||
          "We had a problem updating site visit card, please try again.";
        setMessage({
          trigger: true,
          type: "danger",
          icon: exclamationMark,
          title: "Oops! A problem has occurred!",
          text: errorMessage,
        });
        setLoading(false);
      }
    );

    setTimeout(() => {
      setMessage({
        trigger: false,
      });
    }, 3000);
  };

  const columns = [
    // { id: 1, title: "No status", color: "rgba(172, 12, 12, 0.777)" },
    {
      id: 2,
      title: "Pre-planning phase",
      color: "var(--Primary-Blueberry-700, #325899)",
    },
    { id: 3, title: "Planning", color: "#964B00" },
    { id: 4, title: "Data collection", color: "#3B81FE" },
    { id: 5, title: "Post data collection/Data check", color: "#045B46" },
    // { id: 6, title: "Visit completed", color: "#0F7507" },
  ];

  const formatData = (siteVisitCard) => {
    return {
      id: siteVisitCard?.id,
      content: {
        title: siteVisitCard?.identifier,
        statusUpdated: formatDate(siteVisitCard?.createdDate),
        ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
        state: siteVisitCard?.locationDetails?.country,
        municipality: siteVisitCard?.locationDetails?.municipality,
      },
      hasTracking: siteVisitCard?.tracking != null ? true : false,
      column: siteVisitCard?.siteVisitStatus,
    };
  };

  const formattedData = siteVisitCards.map(formatData);

  return (
    <>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Preview
        visible={showPreviewModal}
        onClose={handleTogglePreviewModal}
        siteVisitCardId={clickedTaskId}
      />
      <AddTrackingCardModal
        visible={showAddTrackingModal}
        onCancel={() => setShowAddTrackingModal(false)}
        onOk={() => {
          fetchSiteVisitCards();
          setShowAddTrackingModal(false);
        }}
        siteVisitCardId={selectedSiteVisit}
        setMessage={setMessage}
      />
      <Spin spinning={loading}>
        <Filters
          onChange={handleFilterChange}
          filters={filters}
          setFilters={setFilters}
          pagination={pagination}
          setPagination={setPagination}
          fetchSiteVisitCards={fetchSiteVisitCards}
        />
        <div id="excludedStrictMode">
          {/* <h1
            style={{
              fontFamily: "Lato",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "22px",
              background: "rgba(250, 220, 234, 0.50)",
              padding: "4px 10px",
              borderRadius: "4px",
            }}
            className="my-4"
          >
            Note: Here you only can se the{" "}
            <span style={{ fontWeight: 900 }}>site visit cards</span> that has
            the
            <span style={{ fontWeight: 900 }}> tracking </span>part.
          </h1> */}
          <KanbanBoardComponent
            tasks={formattedData}
            columns={columns}
            changeStatus={changeSiteVisitCardStatus}
            setClickedTaskId={handleTaskClick}
          />
          <div className="d-flex justify-content-end my-5">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={(page, pageSize) => {
                onPageChange(page, pageSize);
                setPagination({ ...pagination, current: page, pageSize });
              }}
            />
          </div>
        </div>
      </Spin>
    </>
  );
};

export default KanbanView;
