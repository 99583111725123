import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar, Pie } from "react-chartjs-2";
import "./VisitDataSummary.scss";

import calendarIcon from "../../../../Assets/Images/iconCalendar.png";
import { httpService } from "../../../../services/httpService.service";
import dayjs from "dayjs";
import Filters from "./Filters";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const VisitsPlanningExecution = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    state: "",
    startDate: "",
    endDate: "",
  });

  const fetchData = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      StartDate: filters.startDate || dayjs().startOf("month"),
      EndDate: filters.endDate || dayjs().endOf("month"),
      Country: filters.state,
    };

    const queryString = new URLSearchParams(params).toString();

    httpService.get(
      `/api/Site/visitPlanningExecution?${queryString}`,
      (res) => {
        setData(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, [filters]);

  const plannedVisits = data?.visitPlanned || 0;
  const completedVisits = data?.visitCompleted || 0;

  const completedPercentage =
    plannedVisits > 0 ? (completedVisits / plannedVisits) * 100 : 0;

  const hasVisitData = plannedVisits > 0;

  const verticalBarData = {
    labels: ["Visits"],
    datasets: [
      {
        label: "Visits Planned",
        data: hasVisitData ? [100] : [1],
        backgroundColor: hasVisitData ? "#E08585" : "rgba(0,0,0,0.1)",
        borderColor: hasVisitData ? "#E08585" : "rgba(0,0,0,0.1)",
        borderWidth: 1,
      },
      {
        label: "Visits Completed",
        data: hasVisitData ? [completedPercentage] : [1],
        backgroundColor: hasVisitData ? "#B6CE84" : "rgba(0,0,0,0.1)",
        borderColor: hasVisitData ? "#B6CE84" : "rgba(0,0,0,0.1)",
        borderWidth: 1,
      },
    ],
  };

  const verticalBarOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Visits Overview",
        font: {
          size: 18,
          weight: "600",
          style: "normal",
          family: "Poppins",
          lineHeight: "26px",
        },
        color: "#6A113C",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (!hasVisitData) return "No Data Available";
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        position: "bottom",
      },
    },
    scales: {
      y: {
        min: 0,
        max: 100,
        ticks: {
          stepSize: 10,
          callback: function (value) {
            return `${value}%`;
          },
        },
      },
    },
  };

  const hasData =
    (data?.notCompletedWithinDate || 0) > 0 ||
    (data?.completedWithinDate || 0) > 0;
  const pieData = {
    labels: hasData
      ? [
          "Not completed within the planned date",
          "Completed within the planned date",
        ]
      : ["No Data Available"],
    datasets: [
      {
        label: "On-Time Visit Rate",
        data: hasData
          ? [data?.notCompletedWithinDate, data?.completedWithinDate]
          : [1],
        backgroundColor: hasData ? ["#E08585", "#B6CE84"] : ["rgba(0,0,0,0.1)"],
        borderColor: hasData ? ["#E08585", "#B6CE84"] : ["rgba(0,0,0,0.1)"],
        borderWidth: 1,
      },
    ],
  };

  const pieOptions = {
    responsive: true,
    plugins: {
      legend: { position: "bottom" },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return hasData ? tooltipItem.label : "No Data Available";
          },
        },
      },
      title: {
        display: true,
        text: "On-Time Visit Rate",
        font: {
          size: 18,
          weight: "600",
          style: "normal",
          family: "Poppins",
          lineHeight: "26px",
        },
        color: "#6A113C",
      },
    },
  };

  return (
    <>
      <Filters filters={filters} setFilters={setFilters} />
      <div className="chart-card">
        <Bar data={verticalBarData} options={verticalBarOptions} />
      </div>
      <div className="chart-card">
        <Pie data={pieData} options={pieOptions} />
      </div>
      <div className="delays-card">
        <div className="textWrapper">
          <h2>
             Delays between Estimated Start Day and Estimated End Day for Each
            Site
          </h2>
          <h1 className="">{data?.delayDays} days</h1>
        </div>
        <img src={calendarIcon} alt="calendar" />
      </div>
    </>
  );
};

export default VisitsPlanningExecution;
