import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import dayjs from "dayjs";
import { httpService } from "../../../../services/httpService.service";
import Filters from "./Filters";

// Register required components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const IPAnalysis = () => {
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    state: "",
    startDate: "",
    endDate: "",
  });

  useEffect(() => {
    fetchData();
  }, [filters]);

  const fetchData = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      StartDate: filters.startDate || dayjs().startOf("month"),
      EndDate: filters.endDate || dayjs().endOf("month"),
      Country: filters.state,
      ImplementingPartnerId: filters.implementingPartnerId || "",
    };

    const queryString = new URLSearchParams(params).toString();

    httpService.get(
      `/api/Site/ipAnalysis?${queryString}`,
      (res) => {
        setData(res.data?.siteVisitStateIPStatuses || []);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // Extracting dynamic status keys and grouping them by implementing partner
  const staticColors = {
    notInitiated: "#999",
    inProgress: "#FFD88C",
    completed: "#B6CE84",
    postponed: "#E08585",
  };

  const generateColor = (key) => {
    const hash = key
      .split("")
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return `hsl(${hash % 360}, 70%, 60%)`; // Generates consistent color per status
  };

  // Extract all unique status keys
  const allStatusKeys = Array.from(
    new Set(
      data.flatMap((item) =>
        Object.keys(item).filter((key) => key !== "implementingPartnerName")
      )
    )
  );

  // Dynamically create datasets for each status
  const datasets = allStatusKeys.map((status) => ({
    label: status.replace(/([A-Z])/g, " $1").trim(), // Format key names
    data: data.map((item) => item[status] || 0),
    backgroundColor: staticColors[status] || generateColor(status),
    borderColor: staticColors[status] || generateColor(status),
    borderWidth: 1,
  }));

  // Create labels from implementing partner names
  const labels = data.map((item) => item.implementingPartnerName);

  const accessibilityDistributionData = {
    labels,
    datasets,
  };

  const accessibilityDistributionOptions = {
    responsive: true,
    indexAxis: "y", // Horizontal bars
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom",
      },
      title: {
        display: true,
        text: "Status of Site Visits per IP",
        font: {
          size: 18,
          weight: "600",
          style: "normal",
          family: "Poppins",
          lineHeight: "26px",
        },
        color: "#6A113C",
      },
    },
    scales: {
      x: {
        stacked: true, // Enable stacking for the X axis
      },
      y: {
        stacked: true, // Enable stacking for the Y axis
      },
    },
  };

  return (
    <>
      <Filters
        filters={filters}
        setFilters={setFilters}
        implementingPartner={true}
      />
      <div className="chart-card" style={{ width: "100%", minHeight: "400px" }}>
        <Bar
          data={accessibilityDistributionData}
          options={accessibilityDistributionOptions}
        />
      </div>
    </>
  );
};

export default IPAnalysis;
