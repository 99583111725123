import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Input, Select, Button, Space, Divider, Spin } from "antd";
import "./UserManagement.scss";
import cancelX from "../../../Assets/svg/cancelXbutton.svg";
import minusButtonIcon from "../../../Assets/Images/minusButtonIcon.png";
import { httpService } from "../../../services/httpService.service";
import showConfirm from "../../components/ExitConfirm/ExitConfirm";
import AddMemberIcon from "../../../Assets/svg/addMemberIcon.svg";
import exclamationMark from "../../../Assets/svg/exclamationMark.svg";
import Done from "../../../Assets/svg/done.svg";

const { Option } = Select;

const EditMember = ({ visible, onClose, onSubmit, record, setMessage }) => {
  const [form] = Form.useForm();
  const [rolePermissions, setRolePermissions] = useState([{ id: 1 }]);
  const idCounter = useRef(1);
  const [contracts, setContracts] = useState([]);
  const [roles, setRoles] = useState([]);
  const [offices, setOffices] = useState([]);
  const inputRef = useRef(null);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    showConfirm(handleLeave, handleReturn);
  };

  const handleLeave = () => {
    form.resetFields();
    onClose();
  };

  const handleReturn = () => {
    console.log("Cancel action");
  };

  useEffect(() => {
    setLoading(true);
  }, [visible]);

  useEffect(() => {
    if (record) {
      const memberId = record.id;

      fetchOfficeData();

      httpService.get(
        "/api/Account/getAllRoles",
        (res) => setRoles(res.data),
        (error) => console.log(error)
      );

      httpService.get(
        "/api/contract/getAll?pageSize=1000&pageNumber=1",
        (res) => setContracts(res.data),
        (error) => console.log(error)
      );

      httpService.get(
        `/api/User?userId=${memberId}`,
        (res) => {
          setUser(res.data);
          const userData = res.data;

          const officeIds = userData?.offices?.map((office) => office?.id);
          form.setFieldsValue({
            email: userData?.email,
            position: userData?.userDetails?.position,
            office: officeIds,
          });

          if (userData && visible) {
            const userRoles = userData.userContractRoles.map((item, index) => ({
              id: index + 1,
              roleId: item?.role?.id,
              contractId: item?.contract?.id,
            }));
            setRolePermissions(userRoles);
            const initialValues = {};
            userRoles.forEach((role) => {
              initialValues[`role${role.id}`] = role.roleId;
              initialValues[`contract${role.id}`] = role.contractId;
            });
            form.setFieldsValue(initialValues);
          }
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
        }
      );
    }
  }, [record, visible]);

  const fetchOfficeData = (stateId, cityIds) => {
    const params = new URLSearchParams();
    if (!cityIds) {
      [stateId].forEach((id) => params.append("StateIds", id));
    } else {
      cityIds.forEach((id) => params.append("CityIds", id));
    }

    httpService.get(
      `/api/Office/getAll?IgnorePagination=true`,
      (res) => setOffices(res.data),
      (error) => console.log(error)
    );
  };

  const fetchCitiesData = (stateId) => {
    setLoading(true);
    httpService.get(
      `/api/GeoData/cities?stateIds=${stateId}`,
      (res) => {
        setLoading(false);
      },
      (error) => {
        console.log(error);
        setLoading(false);
      }
    );
  };

  const handleRoleChange = (roleId) => {
    const updatedValues = rolePermissions.reduce((acc, role) => {
      acc[`role${role.id}`] = roleId;
      return acc;
    }, {});
    form.setFieldsValue(updatedValues); // Set the selected role for all fields
  };

  const handleAddRolePermissions = () => {
    const newId = idCounter.current + 1;
    idCounter.current = newId;

    const lastRoleId = rolePermissions[rolePermissions.length - 1]?.id;
    const lastRoleValue = form.getFieldValue(`role${lastRoleId}`);

    const newRolePermissions = [...rolePermissions, { id: newId }];
    setRolePermissions(newRolePermissions);

    form.setFieldsValue({
      [`role${newId}`]: lastRoleValue,
    });
  };
  const handleRemoveRolePermissions = (idToRemove) => {
    const updatedPermissions = rolePermissions.filter(
      (role) => role.id !== idToRemove
    );
    form.resetFields([`role${idToRemove}`, `contract${idToRemove}`]);
    setRolePermissions(updatedPermissions);
  };

  const handleOk = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        const { email, position, office, ...rest } = values;

        const officeLocations = Array.isArray(office)
          ? office
          : office
          ? [office]
          : [];
        const contractRoles = rolePermissions.map((role) => ({
          contractId: rest[`contract${role.id}`] || null,
          roleId: rest[`role${role.id}`] || null,
        }));

        // Remove empty or undefined fields from requestBody
        const requestBody = {
          id: user?.id,
          position: position || null,
          officeLocations,
          contractRoles: contractRoles.filter(
            (role) => role.contractId && role.roleId
          ),
        };

        httpService.put(
          "/api/user/updatemember",
          requestBody,
          (response) => {
            form.resetFields();
            handleLeave();
            const message =
              response.message ||
              "A member has been edited and saved to your database!";
            setMessage({
              trigger: true,
              type: "success",
              icon: AddMemberIcon,
              title: "Member edited successfully!",
              text: message,
            });

            onSubmit();
            setLoading(false);
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
          },
          (error) => {
            const errorMessage =
              error?.errors?.[0]?.message ||
              "We had a problem editing this member in your database, please try again.";
            console.error("Error updating member:", error);
            setMessage({
              trigger: true,
              type: "danger",
              icon: exclamationMark,
              title: "Oops! A problem has occurred!",
              text: errorMessage,
            });
            setLoading(false);
            setTimeout(() => {
              setMessage({
                trigger: false,
              });
            }, 3000);
          }
        );
      })
      .catch((info) => {
        console.error("Validate Failed:", info);
        setLoading(false);
      });
  };

  const getAvailableContracts = (index) => {
    const selectedContractIds = rolePermissions
      .filter((_, idx) => idx !== index)
      .map((role) => form.getFieldValue(`contract${role.id}`))
      .filter(Boolean);
    return contracts.filter(
      (contract) => !selectedContractIds.includes(contract.id)
    );
  };

  const validateDuplicateContract = (_, value) => {
    const contractValues = rolePermissions.map((role) =>
      form.getFieldValue(`contract${role.id}`)
    );
    const duplicate =
      contractValues.filter((contract) => contract === value).length > 1;
    return duplicate
      ? Promise.reject(
          new Error(
            "You can not assign more than one role in the same contract"
          )
        )
      : Promise.resolve();
  };

  const handleStateChange = (stateId) => {
    form.resetFields(["office"]);
    setOffices([]);
    if (stateId) {
      fetchOfficeData(stateId);
      fetchCitiesData(stateId);
    }
  };

  return (
    <Modal
      visible={visible}
      closable={false}
      onCancel={handleCancel}
      footer={false}
      width={800}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "20px",
        }}
      >
        <img
          src={cancelX}
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={handleCancel}
          alt="Close"
        />
      </div>
      <h2 className="modalHeader">Edit Member</h2>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <p className="modalsHeader1">Personal Information</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                { required: true, message: "Please enter email" },
                { type: "email", message: "Please enter a valid email" },
              ]}
              style={{ width: "48%" }}
            >
              <Input disabled placeholder="Enter Email" autoComplete="off" />
            </Form.Item>
            <Form.Item
              name="position"
              label="Position"
              rules={[{ required: true, message: "Please select position" }]}
              style={{ width: "48%" }}
            >
              <Input placeholder="Enter Position" autoComplete="off" />
            </Form.Item>
          </div>
          <p className="modalsHeader1">Location Information</p>

          <Form.Item
            label="Office"
            name="office"
            rules={[
              {
                required: true,
                message: "Please select an office",
              },
            ]}
          >
            <Select
              allowClear
              placeholder="Select an office"
              filterOption={(input, option) =>
                option.label.toLowerCase().startsWith(input.toLowerCase())
              }
              options={offices.map((office) => ({
                label: office.name,
                value: office.id,
              }))}
            />
          </Form.Item>
          <p className="modalsHeader1">Roles and Permissions</p>
          {rolePermissions.map((role, index) => (
            <div key={role.id} className="rolesAndPremissions">
              <Form.Item
                name={`role${role.id}`}
                label="Role"
                rules={[{ required: true, message: "Please select role" }]}
                style={{ width: "48%" }}
              >
                <Select
                  placeholder="Select Role"
                  allowClear
                  showSearch
                  onChange={handleRoleChange}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {roles.map((role) => (
                    <Option key={role.id} value={role.id}>
                      {role.roleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={`contract${role.id}`}
                label="Contract"
                rules={[
                  { required: true, message: "Please select contract" },
                  { validator: validateDuplicateContract },
                ]}
                style={{ width: "48%" }}
              >
                <Select
                  placeholder="Select Contract"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .startsWith(input.toLowerCase())
                  }
                >
                  {getAvailableContracts(index).map((contract) => (
                    <Option key={contract.id} value={contract.id}>
                      {contract.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              {rolePermissions.length > 1 && (
                <div onClick={() => handleRemoveRolePermissions(role.id)}>
                  <img
                    src={minusButtonIcon}
                    style={{ cursor: "pointer" }}
                    alt="Remove role permission"
                  />
                </div>
              )}
            </div>
          ))}
          <div
            className="addRolesAndPremissins"
            onClick={handleAddRolePermissions}
          >
            Enroll to another contract
          </div>
          <div className="buttonsModal">
            <Button
              className="inviteButtonTPM"
              key="cancel"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              className="addButtonTPM"
              key="submit"
              type="primary"
              onClick={handleOk}
              loading={loading}
            >
              Edit Member
            </Button>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditMember;
