import { Select, Input, Spin, Table, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import "../Reporting.scss";
import { httpService } from "../../../../services/httpService.service";
import SearchIcon from "./../../../../Assets/svg/searchIcon.svg";
import Preview from "../../planning/VisitBoard/TableView/modals/Preview";
import Edit from "../ReportingList/modals/Edit";
import MessagePopUp from "../../../components/Messages/MessagePopUp";
import { formatDateAndTime } from "../../../../services/helpFunctions/formatDateAndTime";
import { useFilePreview } from "../../completed/ReportingList/Download";
import rejectIcon from "../../../../Assets/svg/cancel-remove-cross-close.svg";
import approveIcon from "../../../../Assets/svg/check-validation-sucess.svg";
import markAsCompletedIcon from "../../../../Assets/svg/check-square-form-validation-checkmark-success-box-square-tick.svg";
import ConfirmModal from "../../../components/confirmModal/ConfirmModal";
import Done from "../../../../Assets/svg/done.svg";
import exclamationMark from "../../../../Assets/svg/exclamationMark.svg";
import previewIcon from "./../../../../Assets/svg/table-action-preview.svg";
import done from "../../../../Assets/svg/done.svg";

function ApproveSiteVisits() {
  const { previewFiles } = useFilePreview();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [paginationForApproval, setPaginationForApproval] = useState({
    pageSize: 10,
    pageNumber: 1,
    total: 0,
  });
  const [siteVisitCards, setSiteVisitCards] = useState([]);
  const [approvedSiteVisitCards, setApprovedSiteVisitCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSiteVisit, setSelectedSiteVisit] = useState(null);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);
  const [searchQueryForApproval, setSearchQueryForApproval] = useState("");
  const [debouncedQueryForApproval, setDebouncedQueryForApproval] = useState(
    searchQueryForApproval
  );
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: "",
    title: "",
    text: "",
  });

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageNumber: 1,
    }));
  };

  const handleSearchForApproval = (e) => {
    setSearchQueryForApproval(e.target.value);
    setPaginationForApproval((prevPagination) => ({
      ...prevPagination,
      pageNumber: 1,
    }));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQueryForApproval(searchQueryForApproval);
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQueryForApproval]);

  const { Option } = Select;

  const fetchSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      Search: debouncedQuery,
      pageSize: pagination.pageSize,
      pageNumber: pagination.pageNumber,
      ApprovalStatus: 1,
    };

    const filteredParams = Object.keys(params).reduce((acc, key) => {
      if (params[key] !== undefined && params[key] !== null) {
        acc[key] = params[key];
      }
      return acc;
    }, {});

    const queryString = new URLSearchParams(filteredParams).toString();

    const apiUrl = `/api/Site/getAllSiteVisits?${queryString}`;
    setLoading(true);
    httpService
      .get(
        apiUrl,
        (res) => {
          setSiteVisitCards(res.data);
          setPagination({
            pageSize: res.metaData.pageSize,
            pageNumber: res.metaData.pageNumber,
            total: res.metaData.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchApprovedSiteVisits = () => {
    const projectId = localStorage.getItem("currentProjectID");

    const params = {
      ProjectId: projectId,
      Search: debouncedQueryForApproval,
      pageSize: paginationForApproval.pageSize,
      pageNumber: paginationForApproval.pageNumber,
      SiteVisitStatus: [2, 3, 4, 5],
      ApprovalStatus: 2,
    };

    const expandArrayParams = (params) => {
      const expanded = [];
      for (const [key, value] of Object.entries(params)) {
        if (Array.isArray(value)) {
          value.forEach((v) => expanded.push([key, v]));
        } else if (
          value !== undefined &&
          value !== null &&
          !(typeof value === "number" && isNaN(value))
        ) {
          expanded.push([key, value]);
        }
      }
      return expanded;
    };

    const queryString = new URLSearchParams(
      expandArrayParams(params)
    ).toString();

    const apiUrl = `/api/Site/getAllSiteVisits?${queryString}`;
    setLoading(true);
    httpService
      .get(
        apiUrl,
        (res) => {
          setApprovedSiteVisitCards(res.data);
          setPaginationForApproval({
            pageSize: res.metaData.pageSize,
            pageNumber: res.metaData.pageNumber,
            total: res.metaData.totalItemCount,
          });
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSiteVisits();
  }, [pagination.pageNumber, pagination.pageSize, debouncedQuery]);

  useEffect(() => {
    fetchApprovedSiteVisits();
  }, [
    paginationForApproval.pageNumber,
    paginationForApproval.pageSize,
    debouncedQueryForApproval,
  ]);

  const handleTogglePreviewModal = (record) => {
    const siteVisitCardId = record?.key;
    setSelectedSiteVisit(siteVisitCardId);
    setShowPreviewModal(!showPreviewModal);
  };

  const columns = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site Visit Name</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Actual date of visit</span>,
      sorter: (a, b) => a.actualDateOfVisit.localeCompare(b.actualDateOfVisit),
      dataIndex: "actualDateOfVisit",
      key: "actualDateOfVisit",
      render: (text) => (
        <span className="styledDataIndex3">{formatDateAndTime(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Resources</span>,
      sorter: (a, b) =>
        a.resources?.join("").localeCompare(b.resources?.join("")),
      dataIndex: "resources",
      key: "resources",
      render: (resources) => {
        return (
          <div className="resourcesColumn">
            {resources?.map((file, index) => (
              <span
                onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                key={index}
              >
                {file?.fileName}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && siteVisitCards.length > 0 ? "right" : undefined,

      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={previewIcon}
            onClick={() => handleTogglePreviewModal(record)}
            style={{ cursor: "pointer" }}
          />
          <Tooltip title="Reject">
            <img
              src={rejectIcon}
              style={{ cursor: "pointer" }}
              alt="reject"
              onClick={() => {
                ConfirmModal(
                  () => handleChangeConfirm(record?.key, "reject"), // Pass the function as a callback
                  "Warning",
                  "Confirming this will reject this site visit! This action can not be undone!", // Information
                  "Do you want to continue?", // Question
                  "Yes" // Confirm button text
                );
              }}
            />
          </Tooltip>
          <Tooltip title="Approve">
            <img
              src={approveIcon}
              style={{ cursor: "pointer" }}
              alt="approve"
              onClick={() => {
                ConfirmModal(
                  () => handleChangeConfirm(record?.key, "approve"), // Pass the function as a callback
                  "Warning", // here it goes the title
                  "Confirming this will approve this site visit! This action can not be undone!", // Information
                  "Do you want to continue?", // Question
                  "Yes" // Confirm button text
                );
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleChangeConfirm = (siteVisitId, type) => {
    if (!siteVisitId) {
      return;
    }
    let approvalStatus;
    if (type == "reject") {
      approvalStatus = 0;
    } else if (type == "approve") {
      approvalStatus = 2;
    }

    setLoading(true);
    httpService.put(
      `/api/Site/changeSiteVisitApprovalStatus?SiteVisitId=${siteVisitId}&ApprovalStatus=${approvalStatus}`,
      "",
      (res) => {
        const message =
          res?.message || "You have successfully updated site visit files!";
        setMessage({
          trigger: true,
          type: "success",
          icon: Done,
          title: `Site visit updated successfully!`,
          text: message,
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
        fetchSiteVisits();
        fetchApprovedSiteVisits();
      },
      (error) => {
        const errorMessage =
          error?.errors?.[0]?.message ||
          "Failed to update this site visit. Please try again later!";

        setMessage({
          trigger: true,
          type: "danger",
          icon: exclamationMark,
          title: "Update Failed",
          text: errorMessage,
        });

        setTimeout(() => {
          setMessage({
            trigger: false,
          });
        }, 3000);
        setLoading(false);
      }
    );
  };

  const formatData = (siteVisitCard) => {
    return {
      key: siteVisitCard?.id,
      siteVisitIdentifier: siteVisitCard?.identifier,
      name: siteVisitCard?.name || "N/A",
      actualDateOfVisit: siteVisitCard?.tracking?.actualDateOfVisit,
      ip: siteVisitCard?.ipPlan?.implementingPartner?.name,
      location: siteVisitCard?.locationDetails?.country,
      cluster: siteVisitCard?.cluster?.name,
      virtual: siteVisitCard?.isVirtual ? "Yes" : "No",
      resources: siteVisitCard?.siteVisitFiles.filter((item) =>
        [1, 3, 4, 5].includes(item.fileCategory)
      ),

      reportingYear: siteVisitCard?.tracking?.reportingYear,
      reportingMonth: siteVisitCard?.tracking?.reportingMonth,
      trainingDate: siteVisitCard?.tracking?.trainingDate,
      trainingScoreInfo: siteVisitCard?.tracking?.trainingScoreInfo,
      dateOfReport: siteVisitCard?.tracking?.dateOfReport,
      dateOfDebrief: siteVisitCard?.tracking?.dateOfDebrief,
      percentageOfDataCollectionCompleted:
        siteVisitCard?.tracking?.percentageOfDataCollectionCompleted,
      fieldTeamReportingStatus:
        siteVisitCard?.tracking?.fieldTeamReportingStatus,
      trainingIsCompleted: siteVisitCard?.tracking?.trainingIsCompleted,
    };
  };

  const formattedData = siteVisitCards?.map(formatData);

  const formattedApprovedData = approvedSiteVisitCards?.map(formatData);

  const onPageChange = (page, pageSize) => {
    setPagination({ ...pagination, pageNumber: page, pageSize: pageSize });
  };

  const handleEdit = () => {
    fetchSiteVisits();

    setShowEditModal(false);
  };

  const columnsApproved = [
    {
      title: <span className="styledTitle">Site visit identifier</span>,
      sorter: (a, b) =>
        a.siteVisitIdentifier.localeCompare(b.siteVisitIdentifier),
      dataIndex: "siteVisitIdentifier",
      key: "siteVisitIdentifier",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Site Visit Name</span>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Actual date of visit</span>,
      sorter: (a, b) => a.actualDateOfVisit.localeCompare(b.actualDateOfVisit),
      dataIndex: "actualDateOfVisit",
      key: "actualDateOfVisit",
      render: (text) => (
        <span className="styledDataIndex3">{formatDateAndTime(text)}</span>
      ),
    },
    {
      title: <span className="styledTitle">IP</span>,
      sorter: (a, b) => a.ip.localeCompare(b.ip),
      dataIndex: "ip",
      key: "ip",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Location</span>,
      sorter: (a, b) => a.location.localeCompare(b.location),
      dataIndex: "location",
      key: "location",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Cluster</span>,
      sorter: (a, b) => a.cluster.localeCompare(b.cluster),
      dataIndex: "cluster",
      key: "cluster",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Vitrual</span>,
      sorter: (a, b) => a.virtual.localeCompare(b.virtual),
      dataIndex: "virtual",
      key: "virtual",
      render: (text) => <span className="styledDataIndex3">{text}</span>,
    },
    {
      title: <span className="styledTitle">Resources</span>,
      sorter: (a, b) =>
        a.resources?.join("").localeCompare(b.resources?.join("")),
      dataIndex: "resources",
      key: "resources",
      render: (resources) => {
        return (
          <div className="resourcesColumn">
            {resources?.map((file, index) => (
              <span
                onClick={(e) => previewFiles(e, file.fileKey, file.id)}
                key={index}
              >
                {file?.fileName}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed:
        isLargeScreen && approvedSiteVisitCards.length > 0
          ? "right"
          : undefined,
      render: (text, record) => (
        <div
          className="actionDiv fitContentColumn"
          style={{ width: "fit-content" }}
        >
          <img
            src={previewIcon}
            onClick={() => handleTogglePreviewModal(record)}
            style={{ cursor: "pointer" }}
          />
          <Tooltip title="Mark as complete">
            <img
              src={markAsCompletedIcon}
              alt="complete"
              style={{ cursor: "pointer" }}
              onClick={() => handleCompleteSiteVisit(record?.key)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleCompleteSiteVisit = (siteVisitId) => {
    if (siteVisitId) {
      setLoading(true);
      httpService.put(
        `/api/Site/changeSiteVisitStatus?SiteVisitId=${siteVisitId}&SiteVisitStatus=6`,
        "",
        (response) => {
          setMessage({
            trigger: true,
            type: "success",
            icon: done,
            title: "Status updated successfully!",
            text: "Site visit status was correctly updated to your database.",
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 300);
          fetchApprovedSiteVisits();
          setLoading(false);
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem updating site visit card, please try again.";
          setMessage({
            trigger: true,
            type: "danger",
            icon: exclamationMark,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setTimeout(() => {
            setMessage({
              trigger: false,
            });
          }, 300);
          setLoading(false);
        }
      );
    }
  };

  return (
    <Spin spinning={loading}>
      <div className="reportingListDiv">
        <span className="tableViewHeaderText">
          Submitted Site Visits for Approval
        </span>
        <Preview
          visible={showPreviewModal}
          onClose={handleTogglePreviewModal}
          siteVisitCardId={selectedSiteVisit}
        />
        <div className="filtersWrapper">
          <div className="containerFilters">
            <Input
              onChange={handleSearch}
              placeholder="Search... "
              suffix={<img src={SearchIcon} alt="Search" />}
            />
          </div>
        </div>
        <div className="d-flex flex-column ">
          <Table
            loading={loading}
            // rowSelection={{ ...rowSelection }}
            dataSource={formattedData}
            columns={columns}
            pagination={{
              ...pagination,
              onChange: onPageChange,
            }}
            className="tableTPM"
            scroll={{ x: "max-content" }}
          />
          <span className="tableViewHeaderText mt-5">Approved Site Visits</span>
          <div className="filtersWrapper">
            <div className="containerFilters">
              <Input
                onChange={handleSearchForApproval}
                placeholder="Search... "
                suffix={<img src={SearchIcon} alt="Search" />}
              />
            </div>
          </div>
          <Table
            rowKey="id"
            dataSource={formattedApprovedData}
            columns={columnsApproved}
            loading={loading}
            className="tableTPM"
            pagination={{
              ...pagination,
              onChange: onPageChange,
            }}
          />
        </div>
        <Preview
          onClose={handleTogglePreviewModal}
          visible={showPreviewModal}
          siteVisitCardId={selectedSiteVisit}
        />
        <Edit
          visible={showEditModal}
          onCancel={() => setShowEditModal(false)}
          onOk={handleEdit}
          siteVisitCardId={selectedSiteVisit}
          setMessage={setMessage}
        />
      </div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
    </Spin>
  );
}

export default ApproveSiteVisits;
