import React, { useState, useEffect } from "react";
import Banner from "../../components/Banner/Banner";
import { Button, Input, Table } from "antd";
import add from "../../../Assets/svg/addButton.svg";
import deleteIcon from "../../../Assets/svg/table-action-delete.svg";
import editIcon from "../../../Assets/svg/table-action-edit.svg";
import previewIcon from "../../../Assets/svg/table-action-preview.svg";
import "./Projects.scss";
import AdminHeader from "../../components/AdminHeader/AdminHeader";
import { Header } from "antd/es/layout/layout";
import back from "../../../Assets/svg/back.svg";
import DeleteComponent from "../../components/DeleteComponent/DeleteComponent";
import NewProject from "./NewProject";
import EditProject from "./EditProject";
import PreviewProject from "./PreviewProject";
import { useNavigate } from "react-router-dom";
import { httpService } from "../../../services/httpService.service";
import MessagePopUp from "../../components/Messages/MessagePopUp";
import IconStrategy from "../../../Assets/svg/iconStrategy.svg";
import SearchIcon from "../../../Assets/svg/searchIcon.svg";
import { isAuthorized } from "../../../services/authService";
import { RolesEnum } from "../../../services/rolesEnum";

const Projects = ({
  notifications,
  setNotifications,
  unreadNotifications,
  setUnreadNotifications,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  const [showPreviewProjectModal, setShowPreviewProjectModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const id = localStorage.getItem("currentContractId");
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState(search);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [message, setMessage] = useState({
    trigger: false,
    type: "",
    icon: null,
    title: "",
    text: "",
  });
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 576);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedQuery(search);
    }, 500);

    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 576);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleTableChange = (newPagination) => {
    fetchProjects(newPagination.current, newPagination.pageSize);
  };

  const fetchProjects = async (current, pageSize, search = "") => {
    setLoading(true);
    httpService.get(
      `/api/Projects/getAll?contractId=${id}&pageNumber=${current}&pageSize=${pageSize}&search=${search}&getDeletedOnly=false`,
      (response) => {
        const projectsData = response.data.map((project) => ({
          key: project?.id,
          projectName: project?.projectName,
          description: project?.description,
          locations: project?.projectDetails?.locations.map(
            (location) => location.name
          ),
          countryName: project?.projectDetails?.country?.stateName || "N/A",
          ...project,
          state: project?.projectDetails?.states[0]?.stateName || "N/A",
          ...project,
        }));

        setProjects(projectsData);
        setPagination((prevPagination) => ({
          ...prevPagination,
          current: response.metaData.pageNumber,
          pageSize: response.metaData.pageSize,
          total: response.metaData.totalItemCount,
        }));

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching projects:", error);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchProjects(pagination.current, pagination.pageSize, debouncedQuery);
  }, [id, debouncedQuery]);

  const handleAddProject = async () => {
    fetchProjects(pagination.current, pagination.pageSize);
  };

  const handleDeleteProject = async (projectId) => {
    try {
      setLoading(true);
      const params = [
        {
          id: projectId,
          isDeleted: false,
        },
      ];
      httpService.delete(
        `/api/Projects/deleteProjects`,
        JSON.stringify(params),
        (response) => {
          const message =
            response.message ||
            "The project has been deleted from your database.";
          setMessage({
            trigger: true,
            type: "success",
            icon: IconStrategy,
            title: "Project deleted successfully!",
            text: message,
          });
          fetchProjects(pagination.current, pagination.pageSize);
          setShowDeleteModal(false);
        },
        (error) => {
          const errorMessage =
            error?.errors?.[0]?.message ||
            "We had a problem deleting this project from your database, please try again.";
          setMessage({
            trigger: true,
            type: "danger",
            icon: IconStrategy,
            title: "Oops! A problem has occurred!",
            text: errorMessage,
          });
          setLoading(false);
        }
      );
    } catch (error) {
      const errorMessage =
        error ||
        "We had a problem deleting this project from your database, please try again.";
      setMessage({
        trigger: true,
        type: "danger",
        icon: IconStrategy,
        title: "Oops! A problem has occurred!",
        text: errorMessage,
      });
      setLoading(false);
    }
    setMessage({
      trigger: false,
    });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setPagination({ ...pagination, current: 1 });
  };

  const goBack = () => {
    navigate("/dashboard/contracts");
  };

  const rowClickHandler = (record) => {
    onProjectClick(record);
  };

  const onProjectClick = (record) => {
    const projectId = record.id;
    const projectName = record?.projectName;
    localStorage.setItem("currentProjectID", projectId);
    localStorage.setItem("projectRoundName", projectName);

    if (
      isAuthorized([
        RolesEnum.ProjectManager,
        RolesEnum.SystemAdmin,
        RolesEnum.FieldCoordinator,
        RolesEnum.FieldTeamLeader,
      ])
    ) {
      navigate("/project/dashboard");
    } else {
      navigate("/project/planning?visits");
    }
  };

  const handleEdit = (record) => {
    setSelectedProject(record);
    setShowEditProjectModal(true);
  };

  const columns = [
    {
      title: <span className="styledTitle">Project Round Name</span>,
      dataIndex: "projectName",
      key: "projectName",
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      render: (text, record) => (
        <span
          className="styledDataIndex1"
          onClick={() => onProjectClick(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Round Code</span>,
      dataIndex: "projectNumber",
      key: "projectNumber",
      sorter: (a, b) => a.projectNumber.localeCompare(b.projectNumber),
      render: (text, record) => (
        <span
          className="styledDataIndex1"
          onClick={() => onProjectClick(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Description</span>,
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (text, record) => (
        <span
          className="styledDataIndex3"
          onClick={() => onProjectClick(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Country</span>,
      dataIndex: "countryName",
      key: "countryName",
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
      render: (text, record) => (
        <span
          className="styledDataIndex3"
          onClick={() => onProjectClick(record)}
        >
          {text}
        </span>
      ),
    },

    {
      title: <span className="styledTitle">Governorate/State</span>,
      dataIndex: "state",
      key: "state",
      sorter: (a, b) => a.state.localeCompare(b.state),
      render: (text, record) => (
        <span
          className="styledDataIndex3"
          onClick={() => onProjectClick(record)}
        >
          {text}
        </span>
      ),
    },
    {
      title: <span className="styledTitle">Locations</span>,
      dataIndex: "locations",
      key: "locations",
      sorter: (a, b) =>
        a.locations.join(", ").localeCompare(b.locations.join(", ")),
      render: (locations, record) => (
        <span
          className="styledDataIndex3"
          onClick={() => onProjectClick(record)}
        >
          {locations.join(", ")}
        </span>
      ),
    },
    {
      title: <span className="styledTitle"></span>,
      key: "action",
      fixed: isLargeScreen && projects.length > 0 ? "right" : undefined,
      render: (text, record) => (
        <div className="actionDiv">
          <img
            src={previewIcon}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedProject(record);
              setShowPreviewProjectModal(true);
            }}
          ></img>
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={editIcon}
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(record)}
            />
          )}
          {isAuthorized([RolesEnum.ProjectManager, RolesEnum.SystemAdmin]) && (
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowDeleteModal(true);
                setProjectToDelete(record?.id);
              }}
            ></img>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <MessagePopUp
        trigger={message.trigger}
        type={message.type}
        icon={message.icon}
        messageTitle={message.title}
        messageText={message.text}
      />
      <Header
        className="adminHeader "
        style={{ minHeight: "0 !important", padding: "0px !important" }}
      >
        <div
          style={{ display: "flex" }}
          className="ms-4"
          onClick={() => {
            // localStorage.removeItem("roleId");
            goBack();
          }}
        >
          <img src={back} style={{ cursor: "pointer" }}></img>{" "}
          <span className="backText">Go back to contracts</span>
        </div>
        <AdminHeader
          notifications={notifications}
          setNotifications={setNotifications}
          unreadNotifications={unreadNotifications}
          setUnreadNotifications={setUnreadNotifications}
        >
          {" "}
        </AdminHeader>
      </Header>
      <Banner text="TPM Project Rounds/Visiting Rounds"></Banner>{" "}
      <div style={{ background: "#F8F8F8", padding: "40px" }}>
        <div className="mainDivProjects">
          <div className="userManagementHeader">
            <div className="userManagementHeaderFirst">
              <span className="userManagementHeaderText">
                My Project Rounds
              </span>
            </div>
            <div className="userManagementHeaderSecond">
              {isAuthorized([
                RolesEnum.ProjectManager,
                RolesEnum.SystemAdmin,
              ]) && (
                <Button
                  className="addButtonTPM"
                  onClick={() => {
                    setShowNewProjectModal(true);
                  }}
                >
                  <img src={add}></img>Add New Project Round
                </Button>
              )}
            </div>
          </div>
          <div className="membersText">
            Select a round to see the progress of your project
          </div>
          <div className="filtersWrapper">
            <span style={{ fontWeight: "bold" }}>Search</span>
            <Input
              placeholder="Search by project round name..."
              value={search}
              allowClear
              suffix={<img src={SearchIcon} alt="Search" />}
              onChange={handleSearchChange}
            />
          </div>
          <div style={{ overflowX: "auto", marginTop: "20px" }}>
            <Table
              // rowSelection={{ ...rowSelection, checkStrictly }}
              dataSource={projects}
              columns={columns}
              className="tableTPM"
              loading={loading}
              style={{ cursor: "pointer" }}
              pagination={{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                showSizeChanger: true,
              }}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: (event) => {
                  if (!event.target.closest(".ant-table-cell:last-child")) {
                    rowClickHandler(record);
                  }
                },
              })}
            />
          </div>
        </div>
      </div>
      <DeleteComponent
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        text={
          "You're about to delete this project. This action can’t be undone."
        }
        header={"Delete Project"}
        onConfirm={() => handleDeleteProject(projectToDelete)}
        loading={loading}
      />
      <NewProject
        visible={showNewProjectModal}
        onClose={() => setShowNewProjectModal(false)}
        onSubmit={handleAddProject}
        setMessage={setMessage}
      />
      <EditProject
        visible={showEditProjectModal}
        onClose={() => setShowEditProjectModal(false)}
        project={selectedProject}
        onSubmit={handleAddProject}
        setMessage={setMessage}
      />
      <PreviewProject
        visible={showPreviewProjectModal}
        onClose={() => setShowPreviewProjectModal(false)}
        project={selectedProject}
      />
    </div>
  );
};
export default Projects;
